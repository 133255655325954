// async
export const FETCH_NOTIFICATIONS_LIST_REQUEST = 'FETCH_NOTIFICATIONS_LIST_REQUEST';
export const FETCH_NOTIFICATIONS_LIST_SUCCESS = 'FETCH_NOTIFICATIONS_LIST_SUCCESS';
export const FETCH_NOTIFICATIONS_LIST_ERROR = 'FETCH_NOTIFICATIONS_LIST_ERROR';

export const FETCH_NOTIFICATIONS_LIST_TALLY_SUCCESS = 'FETCH_NOTIFICATIONS_LIST_TALLY_SUCCESS';
export const FETCH_NOTIFICATIONS_LIST_TALLY_ERROR = 'FETCH_NOTIFICATIONS_LIST_TALLY_ERROR';

export const MARK_NOTIFICATION_READ_REQUEST = 'MARK_NOTIFICATION_READ_REQUEST';
export const MARK_NOTIFICATION_READ_SUCCESS = 'MARK_NOTIFICATION_READ_SUCCESS';
export const MARK_NOTIFICATION_READ_ERROR = 'MARK_NOTIFICATION_READ_ERROR';

export const SET_NOTIFICATIONS_UNREAD_COUNT = 'SET_NOTIFICATIONS_UNREAD_COUNT';

// non-async