import React, { useEffect, useState } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import { DatePicker, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import moment from 'moment';
import { isFunction } from 'helpers/utils';
import { StyledEngineProvider } from '@mui/material/styles';
import { TextField } from '@mui/material';

const DatePickerField = ({  
    field,
    form: { touched, errors, setFieldValue },
    ...props
}) => {
    const [datePickerValue, setDatePickerValue] = useState(null);

    const DateInputFormat = 'DD/MM/YYYY';

    return (
        <>
            <label>{props.label}</label>
            {!props.isMaterialDatePicker ? <><AntDatePicker
                className={`Form-DatePicker ant-datepicker ${touched[field.name] && errors[field.name] && 'Error'}`}
                {...field}
                {...props}
                onChange={(value) => {
                    let newDtValue = value;

                    if (isFunction(props.onChange)) {
                        props.onChange(value);
                    }

                    if(value === null) {
                        newDtValue = '';
                    }

                    setFieldValue(field.name, newDtValue)
                }}
                format={DateInputFormat}
            />
            </>
            : 
            <StyledEngineProvider injectFirst>
                {<LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={datePickerValue}
                        inputFormat={'dd/MM/yyyy'}
                        onChange={(newValue) => {
                            setDatePickerValue(newValue);
                            setFieldValue(field.name, moment(newValue));
                        }}
                        openTo='month'
                        views={['month', 'day', 'year']}
                        renderInput={(params) => {
                            return <TextField
                                className='DatePicker-Input'
                                placeholder='DD/MM/YYYY'
                                {...params}
                            />
                        }}
                    />
                </LocalizationProvider>}
            </StyledEngineProvider>}
            {touched[field.name] && errors[field.name] && (
                <p className='text-danger Error-Text'>{errors[field.name]}</p>
            )}
        </>
    )
};

export default DatePickerField;
