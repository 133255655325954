import React, { FunctionComponent } from "react";
import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText, IconButton, withStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import {
  InputField,
  SelectField,
  DatePickerField,
} from "../../components/Controls";
import { Field, Form, Formik } from "formik";
import {Select, Button, message} from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as Yup from "yup";
import UserSVG from "assets/svgs/user-profile.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "styles/Settings.scss";
import { setCurrentLanguage } from "../../redux/actions/i18n";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./../../hooks/use-app-selector";
import styled from "styled-components";
import {AiOutlineDelete} from "react-icons/all";
import CloseIcon from '@material-ui/icons/Close';
import {keycloak} from "../../api/keycloak";
import Cookies from "js-cookie";
import {TOKEN_NAME} from "../../constants";
import { Modal } from 'antd';
import {deletePatient} from "../../api/patients";
import {useHistory} from "react-router-dom";

const { confirm } = Modal;

interface SettingsModuleProps {
  isOpen: boolean;
  handleClose: () => void;
}

const DeleteAccountButton = styled.span`
  color: rgba(239, 68, 68, 1);
  display: inline-flex;
  align-items: center;
  
  &:hover {
    cursor: pointer;
    color: rgba(185, 28, 28, 1);
  }
`

const SettingsModule: FunctionComponent<SettingsModuleProps> = ({
  isOpen,
  handleClose,
}) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down(576)); // defined in styles/_responsive.scss
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const permissions = useAppSelector((state) => state.permissions.permissions!);
  const currentUser = useAppSelector(state => state.currentUser.data);
  const history = useHistory();

  const showConfirmDeleteAccountModal = (patientId) => {
    confirm({
      title: 'Confirm',
      content: <>Are you sure you want to delete your account?<br/><br/>This is irreversible.</>,
      okText: 'Yes',
      cancelText: 'No',
      maskClosable: true,
      onOk: async () => {
        try {
          message.loading(t('message.deleting_account'), 0);
          await deletePatient(patientId);
          if (keycloak) {
            keycloak.logout();
          } else if (Cookies.get(TOKEN_NAME)) {
            Cookies.remove(TOKEN_NAME);
            history.push('/');
          }
          message.destroy();
          message.success(t('message.delete_account_success'))
        } catch {
          message.destroy();
          message.error(t('message.delete_account_error'))
        }
      },
    });
  };


  return (
    <Dialog className="Settings-Dialog" open={isOpen} fullScreen={isFullScreen}>
      <div className="Settings-Wrapper h-100">
        <DialogTitle
          className="Settings-Title text-center"
          id="responsive-dialog-title"
        >
          <Container>
            <Row>
              <Col>
                <img
                  alt="profile"
                  className="Settings-UserProfile"
                  src={UserSVG}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <h2>Profile Settings</h2>
              </Col>
            </Row>
            <IconButton aria-label="close" style={{
              position: "absolute",
              right: 5,
              top: 5
            }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Container>
        </DialogTitle>
        <DialogContent className="Settings-Content bg-white">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              dateOfBirth: moment(),
              contactNumber: "",
              email: "",
              preferredLanguage: "en",
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required(
                t("message.first_name_required")
              ),
              lastName: Yup.string().required(t("message.last_name_required")),
              dateOfBirth: Yup.string().required(
                t("message.date_of_birth_required")
              ),
              contactNumber: Yup.string().required(
                t("message.contact_no_required")
              ),
              email: Yup.string().required(t("message.email_required")),
              preferredLanguage: Yup.string().required(
                t("message.preferred_language_required")
              ),
            })}
            onSubmit={(values) => {
              i18n.changeLanguage(values.preferredLanguage);
              dispatch(setCurrentLanguage(values.preferredLanguage));
              handleClose();
            }}
          >
            <Form>
              <Container fluid>
                <Row>
                  {permissions.Settings?.includes(
                      "settings:update-name"
                  ) ? <>
                  <Col xs={12} md={6}>
                    <Field
                      id="firstName"
                      name="firstName"
                      type="text"
                      label={t("label.first_name")}
                      placeholder={t("placeholder.first_name")}
                      size="large"
                      component={InputField}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      id="lastName"
                      name="lastName"
                      type="text"
                      label={t("label.last_name")}
                      placeholder={t("placeholder.last_name")}
                      size="large"
                      component={InputField}
                    />
                  </Col>
                  </> : null}
                  {permissions.Settings?.includes(
                      "settings:update-date-of-birth"
                  ) ? <Col xs={12} md={6}>
                    <Field
                      id="dateOfBirth"
                      name="dateOfBirth"
                      label={t("label.date_of_birth")}
                      placeholder={t("placeholder.date_of_birth")}
                      component={DatePickerField}
                      size="large"
                    />
                  </Col> : null}
                  {permissions.Settings?.includes(
                      "settings:update-contact-number"
                  ) ? <Col xs={12} md={6}>
                    <Field
                      id="contactNumber"
                      name="contactNumber"
                      type="text"
                      label={t("label.contact_number")}
                      placeholder={t("placeholder.contact_number")}
                      size="large"
                      component={InputField}
                    />
                  </Col> : null}
                </Row>
                <Row>
                  {permissions.Settings?.includes(
                      "settings:update-email"
                  ) ?  <Col xs={12} md={6}>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      label={t("label.email")}
                      placeholder={t("placeholder.email")}
                      size="large"
                      component={InputField}
                    />
                  </Col> : null}
                  {permissions.Settings?.includes(
                    "settings:update-preferred-language"
                  ) ? (
                    <Col>
                      <Field
                        id="preferredLanguage"
                        name="preferredLanguage"
                        label={t("label.preferred_language")}
                        placeholder={t(
                          "placeholder.preferred_language_required"
                        )}
                        onChange={(value: any) => {
                          console.log(value);
                        }}
                        size="large"
                        style={{
                          width: "100%",
                          // zIndex: '9999'
                        }}
                        dropdownStyle={{
                          zIndex: 99999999,
                        }}
                        component={SelectField}
                      >
                        <Select.Option value="en">English</Select.Option>
                        <Select.Option value="fr">French</Select.Option>
                        <Select.Option value="ko">Korean</Select.Option>
                        <Select.Option value="ja">Japanese</Select.Option>
                      </Field>
                    </Col>
                  ) : null}
                </Row>
                {permissions.Settings?.length > 0 ? <Row className="mt-5">
                  <Col>
                    <Button htmlType="submit" type="primary" block>
                      {t("label.save")}
                    </Button>
                  </Col>
                </Row> : null}
                <Row className="mt-3">
                  <Col>
                    <Button onClick={handleClose} block>
                      {t("label.cancel")}
                    </Button>
                  </Col>
                </Row>
                <Row className={"mt-5"}>
                  {permissions.Settings?.includes("settings:delete-account") ? <Col>
                    <DeleteAccountButton onClick={() => {
                      handleClose()
                      showConfirmDeleteAccountModal(currentUser.id)
                    }}><AiOutlineDelete style={{
                      marginRight: "5px"
                    }} />{t("label.delete_account")}</DeleteAccountButton>
                  </Col> : null}
                </Row>
              </Container>
            </Form>
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default SettingsModule;
