import { Button, Card, Empty, message } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCollateralFileUrl, getAppointmentCollaterals } from 'api/appointmentCollaterals';
import { downloadURI } from 'helpers/utils';
import moment from 'moment';

function CollateralList({appointmentId}) {
    const [ collaterals, setCollaterals ] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if(collaterals.length === 0 && appointmentId) {
            const params = {
                appointmentId: appointmentId,
                length: 999, // TBC: How to know the length of collaterals to be shown in the appointment
                page: 0,
                sortColumn: 'id',
                sortDirection: 'ASC'
            }
            fetchAppointmentCollaterals(appointmentId, params);
        }
    }, [appointmentId]);

    const fetchAppointmentCollaterals = async (id, params) => {
        const res = await getAppointmentCollaterals(id, params);
        setCollaterals(res.data.data);
    }

    const handleDownloadPreviewLink = (appointmentCollateralId, collateral) => {
        getCollateralFileUrl(appointmentCollateralId)
          .then(res=>{
              if(collateral?.file) {
                   const fileName = collateral.file.originalFilename || collateral.name;
                    if (collateral.hasOwnProperty('url')) {
                        window.open(res.data);
                    } else {
                        downloadURI(res.data, fileName)
                    }
              } else if(collateral?.url) {
                    window.open(res.data , '_blank');
              } else {
                    message.error('Error To Open Resources.');    
              }
          })
          .catch(err => {
              message.error('Error downloading file.');
          })
    };

    return (
        <Container className='Appointment-Info-Container' fluid>
            <Card className='Card-Gradient' bodyStyle={{ padding: 12 }}>
                {collaterals && collaterals.length < 1 && (
                    // <Container fluid>
                    <Row>
                        <Col className='text-center mt-4 mb-4' style={{ minHeight: '23vh' }}>
                            <Empty description={t('message.no_collateral')} />
                        </Col>
                    </Row>
                    // </Container>
                )}
                <Row>
                    {collaterals && collaterals.length > 0 && collaterals.map((appointmentCollateral, i) => (
                        // <Container className='Appointment-Info-Container' fluid>
                        <Row className='mb-3'>
                            <Col xs={7} md={8} lg={8}>
                                <Button
                                    type='link'
                                    className='Link-Data col-md-12 text-center'
                                    onClick={() => handleDownloadPreviewLink(appointmentCollateral.id, appointmentCollateral.collateral)}
                                >
                                    {/* {appointmentCollateral && appointmentCollateral.collateral.hasOwnProperty('url') ? t('label.open_link') : t('label.download_file')} */}
                                    {appointmentCollateral.collateral.name}
                                </Button>
                            </Col>
                            <Col xs={5} md={4} lg={4}>
                                <span className='font-weight-bold text-center'>{moment(appointmentCollateral.createdAt).format("DD MMM hh:mm A")}</span>
                            </Col>
                            {/* <Col xs={6} md={5} lg={5}>
                                <Button
                                    type='primary'
                                    size='small'
                                    className='Action-Button float-right'
                                    onClick={() => handleDownloadPreviewLink(appointmentCollateral.id, appointmentCollateral.collateral)}
                                >
                                    {appointmentCollateral && appointmentCollateral.collateral.hasOwnProperty('url') ? t('label.open_link') : t('label.download_file')}
                                </Button>
                            </Col> */}
                        </Row>
                        // </Container>
                    ))}
                </Row>
            </Card>
        </Container>
    );
}

export default CollateralList;