import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import {keycloak, initOptions} from './api/keycloak';
import { getBrandingByTenant, getMultipleTenantBranding } from 'api/branding';
import {fetchTenantUpcomingAppointmentsExtraOptions, setTenantBranding} from './redux/actions/tenants';

import 'App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sb-admin-2.scss'
import 'style.scss'

import MainLayout from 'components/Layout/MainLayout';
import Alert from "react-bootstrap/cjs/Alert";
import 'antd/dist/antd.css';
import Signup from "./pages/Signup";
import {Redirect, Switch, useHistory, useLocation} from "react-router";
import {AuthContext, useAuthContext} from "./hooks";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import routes from "./routes";

import Landing from 'modules/LoginModule';
import OTPComponent from 'modules/LoginModule/components/OTPComponent';
import {getToken} from './api/token';
import {useAppSelector} from './hooks/use-app-selector';
import {useDispatch} from 'react-redux';
import {AUTH_ACTIONS} from './redux/types/auth-types';
import Cookies from 'js-cookie';
import { TOKEN_NAME, TOKEN_KEY } from './constants/index';
import { createGoogleFontLinkTag } from 'helpers/utils';
import GlobalStyle from 'theme';
import CollectionNotice from './components/CollectionNotice';
import IdleTimer from 'react-idle-timer';
import { Beforeunload } from 'react-beforeunload';
import { getSetting } from 'api/settings';
import { logout } from 'api/login';
import { getunreadMessages } from 'api/messages';
import { setMessagesUnreadCount } from 'redux/actions/messages';

function App() {
    const signupUri = '/signup';
    const landingUri = '/';

    const [sessionState] = useState({
        sessionDetails: [
            {fullName: 'Hisham Alabri'}],
        otherState: 'not sure what this is'
    });

    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [idleTimerInSeconds, setIdleTimer] = useState(900);
    const defaultUri = routes(isAuthenticated)[0].path;
    const auth = useAppSelector(state => state.auth);
    const tenant = useAppSelector(state => state.tenant);
    const dispatch = useDispatch();
    const history = useHistory();

    const { branding } = tenant;

    useEffect(() => {
        async function fetchCountData() {
            const unreadMessageCount = await getunreadMessages();
            dispatch(setMessagesUnreadCount(unreadMessageCount.data));
        }
        if (isAuthenticated) {
            fetchCountData();
        }
    }, [isAuthenticated]);

    const buildGoogleFontApiSource = (typography) => {
        if (typography?.fontUrl) {
            createGoogleFontLinkTag(typography.fontUrl);
        }
    }

    const setPageTitle = () => {
        if (branding.page.appTitle) {
            document.title = 'Patient Portal - ' + branding.page.appTitle
        }
    }

    const getPatientPortalIdleTimeout = async () => {
        const response = await getSetting({
            category: 'Patient Portal',
            dataType: 'NUMBER',
            name: 'IDLE_TIMEOUT',
            defaultValue: 'true',
        });

        if (response?.data?.value) {
            setIdleTimer(parseInt(response.data.value));
        }
    }

    useEffect(() => {
        if (!sessionStorage.getItem(TOKEN_NAME) && Cookies.get(TOKEN_NAME)) {
            Cookies.remove(TOKEN_NAME);
            window.location.reload();
        }
        setPageTitle();
        const requestBrandingBasedOnSettings = async () => {
            try {
                const response = await getMultipleTenantBranding(`${tenant.uniqueId.replace('.patient', '')}, ${tenant.uniqueId}`);

                if (response.data.data.theme) {
                    const adminBranding = response.data.data.theme.find(responseDataTheme => JSON.parse(responseDataTheme).tenantUniqueId === tenant.uniqueId.replace('.patient', ''));
                    const patientBranding = response.data.data.theme.find(responseDataTheme => JSON.parse(responseDataTheme).tenantUniqueId === tenant.uniqueId);
                    const adminJsonBranding = JSON.parse(adminBranding);

                    if (adminJsonBranding.centraliseBranding === true) {
                        buildGoogleFontApiSource(adminJsonBranding.theme.typography);
                        dispatch(setTenantBranding(adminJsonBranding));
                    } else {
                        const patientJsonBranding = JSON.parse(patientBranding);
                        buildGoogleFontApiSource(patientJsonBranding.theme.typography);
                        dispatch(setTenantBranding(patientJsonBranding));
                    }
                }
            } catch (error) {
                console.log(error);

                if (branding) {
                    dispatch(setTenantBranding(branding));
                }
            }
        }

        requestBrandingBasedOnSettings();
        dispatch(fetchTenantUpcomingAppointmentsExtraOptions())
    }, []);

    useEffect(() => {
        setIsAuthenticated(!!auth.token)

        /* @TODO fix keycloak
                keycloak.init({
                    onLoad: initOptions.onLoad,
                    checkLoginIframe: false
                }).then((auth) => {
                    console.log('in keycloak init ', auth);
                    setIsAuthenticated(auth);
                }).catch((e) => {
                    console.error("Authentication Failed");
                    console.error(e);
                });
        */
    }, [auth]);

    useEffect(() => {
        // @todo check expiry of token

        dispatch({
            type: AUTH_ACTIONS.SET_TOKEN,
            payload: {token: Cookies.get(TOKEN_NAME)}
        })

        if (Cookies.get(TOKEN_NAME)) {
            getPatientPortalIdleTimeout();
        }
    }, [Cookies.get(TOKEN_NAME)]);

    const handleOnIdle = () => {
        if (Cookies.get(TOKEN_NAME)) {
            let tokenKey = Cookies.get(TOKEN_KEY);
            logout({ tokenKey: tokenKey })
                .then((data) => {
                    console.log('logout', data);
                    Cookies.remove(TOKEN_NAME);
                    Cookies.remove(TOKEN_KEY);
                    window.location.reload();
                }).catch(err => err);
        }
    };

    return (
        <Beforeunload onBeforeunload={(event) => {
            // event.preventDefault();
            if (!sessionStorage.getItem(TOKEN_NAME)) {
                Cookies.remove(TOKEN_NAME);
                history.push('/');
            }
            // return null;
        }}>
            <IdleTimer
                timeout={(idleTimerInSeconds * 1000)}
                onIdle={handleOnIdle}
            />
            <AuthContext.Provider value={isAuthenticated}>
                <GlobalStyle branding={branding} />
                <BrowserRouter basename=''>
                    <Switch>
                        <Route path={`/collection-notice`}>
                        <CollectionNotice />
                        </Route>
                        <Route path="/console/meeting/:meetingNumber/:meetingPassword/:appointmentId"
                            component={(props) => {

                                console.log('starting a meeting with', props.match.params.meetingNumber);
                                window.location.href = 'http://localhost:3001/meeting/'
                                    + props.match.params.meetingNumber + "/"
                                    + props.match.params.meetingPassword + "/"
                                    + props.match.params.appointmentId;
                                return null;
                            }}/>
                        <AuthenticatedRoute
                            path={signupUri}
                            onAuthenticated={
                                () => <Redirect to={defaultUri}/>
                            }
                            onUnauthenticated={
                                () => <Signup signupUri={signupUri}/>
                            }
                        />

                        <AuthenticatedRoute
                            exact path={landingUri}
                            onAuthenticated={
                                () => {
                                    console.log(`on Authenticated route landing: redirect to ${defaultUri}`);
                                    return <Redirect to={defaultUri}/>;
                                }
                            }
                            onUnauthenticated={
                                () => {
                                    console.log('on Unauthenticated route Landing: render Landing');
                                    return <Landing keycloak={keycloak}/>;
                                }
                            }
                        />

                        <AuthenticatedRoute
                            onAuthenticated={
                                () => {
                                    console.log('on Authenticated route main: render Main');
                                    return <MainLayout fullName={sessionState.sessionDetails[0].fullName}/>;
                                }
                            }
                            onUnauthenticated={
                                () => {
                                    console.log('on Unauthenticated route main: redirect to Landing')
                                    return <Redirect to={landingUri}/>;
                                }
                            }
                        />

                    </Switch>
                    <Alert></Alert>
                </BrowserRouter>
            </AuthContext.Provider>
        </Beforeunload>
    );
}

export default App;