import {
    getAppointmentById,
    getAppointmentsBySpan,
    updateAppointmentStatus,
} from 'api/appointments';

import {
    uploadAttachment,
    deleteAttachment
} from 'api/attachments'

import {
    CONFIRM_APPOINTMENT_ERROR,
    CONFIRM_APPOINTMENT_REQUEST,
    CONFIRM_APPOINTMENT_SUCCESS,
    FETCH_APPOINTMENT_ERROR,
    FETCH_APPOINTMENT_REQUEST,
    FETCH_APPOINTMENT_SUCCESS,
    FETCH_APPOINTMENTS_LIST_ERROR,
    FETCH_APPOINTMENTS_LIST_REQUEST,
    FETCH_APPOINTMENTS_LIST_SUCCESS,
    UPLOAD_ATTACHMENT_REQUEST,
    UPLOAD_ATTACHMENT_SUCCESS,
    UPLOAD_ATTACHMENT_ERROR,
    DELETE_ATTACHMENT_REQUEST,
    DELETE_ATTACHMENT_SUCCESS,
    DELETE_ATTACHMENT_ERROR,
    SET_WILL_TASK_LIST_FETCH,
    UPDATE_APPOINTMENTS_PARAMS
} from "./types/appointment";

export const setWillTaskListFetch = (willTaskListFetch) => {
    return {
        type: SET_WILL_TASK_LIST_FETCH,
        payload: willTaskListFetch
    }
}

export const fetchAppointmentsBySpan = (span, params) => {
    return async dispatch => {
        try {
            dispatch({
                type: FETCH_APPOINTMENTS_LIST_REQUEST,
            });

            const response = await getAppointmentsBySpan(span, params);

            dispatch({
                type: FETCH_APPOINTMENTS_LIST_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: FETCH_APPOINTMENTS_LIST_ERROR,
            });
        }
    }
};

export function fetchAppointmentRequest(id) {
    return async (dispatch) => {
        try {
            dispatch({
                type: FETCH_APPOINTMENT_REQUEST
            })
            const res = await getAppointmentById(id);
            dispatch({
                type: FETCH_APPOINTMENT_SUCCESS,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: FETCH_APPOINTMENT_ERROR,
                error
            })
        }
    }
}

export const confirmAppointmentStatus = id => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CONFIRM_APPOINTMENT_REQUEST
            });

            const response = await updateAppointmentStatus({
                id,
                status: 'CONFIRMED'
            });

            dispatch({
                type: CONFIRM_APPOINTMENT_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: CONFIRM_APPOINTMENT_ERROR,
                error
            });
        }
    }
}
export const checkedInAppointmentStatus = id => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CONFIRM_APPOINTMENT_REQUEST
            });

            const response = await updateAppointmentStatus({
                id,
                status: 'CHECKED_IN'
            });

            dispatch({
                type: CONFIRM_APPOINTMENT_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: CONFIRM_APPOINTMENT_ERROR,
                error
            });
        }
    }
}
export const declineAppointmentStatus = id => {
    return async (dispatch) => {
        try {
            dispatch({
                type: CONFIRM_APPOINTMENT_REQUEST
            });

            const response = await updateAppointmentStatus({
                id,
                status: 'DECLINED'
            });

            dispatch({
                type: CONFIRM_APPOINTMENT_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: CONFIRM_APPOINTMENT_ERROR,
                error
            });
        }
    }
}

export const uploadAttachmentRequest = (payload) => {
    return async dispatch => {
        try {
            dispatch({
                type: UPLOAD_ATTACHMENT_REQUEST
            });

            const response = await uploadAttachment(payload);

            dispatch({
                type: UPLOAD_ATTACHMENT_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: UPLOAD_ATTACHMENT_ERROR,
                error
            });
        }
    }
};

export const deleteAttachmentRequest = appointmentId => {
    return async dispatch => {
        try {
            dispatch({
                type: DELETE_ATTACHMENT_REQUEST
            });

            const response = await deleteAttachment(appointmentId);

            dispatch({
                type: DELETE_ATTACHMENT_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: DELETE_ATTACHMENT_ERROR,
                error
            });
        }
    }
};

export const updateAppointmentsParams = (data) => {
    return {
        type: UPDATE_APPOINTMENTS_PARAMS,
        payload: data
    }
}
