import { Button } from 'antd';
import React, { useState } from 'react';
import { Statistic } from 'antd';
import { signUpGeneral } from 'api/signup';

const RESEND_OTP_COOLDOWN = 300000;

const { Countdown } = Statistic;

const ResendOTPButton = (props) => {
    const [resendOtpOnCooldown, setResendOtpOnCooldown] = useState(false);

    return  resendOtpOnCooldown ? 
            <>
                <span>
                    You can resend your OTP after <span></span>
                </span><Countdown 
                            value={Date.now() + RESEND_OTP_COOLDOWN} onFinish={() => setResendOtpOnCooldown(false)} /><span>seconds</span>
            </>
            : <Button
                {...props}
                onClick={() => {
                    signUpGeneral({
                        mobileNumber: props.mobileNumber
                    }).then((res) => {
                        setResendOtpOnCooldown(true);
                        if (props?.onClickCallback) {
                            props.onClickCallback({
                                uniqueId: res?.data?.data?.uniqueId
                            });
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
                }}
            >
                Tap here to resend
            </Button>
}

export default ResendOTPButton;
