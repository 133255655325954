import { FieldComponent } from 'interfaces';

export const getFormFieldsDefaultValues = (
    formFields: FieldComponent[]
) => {
    let newFormFields:any = {};

    formFields 
        && formFields.length > 0 
            && formFields.map(
                formField => {
                    if (formField) {
                        const { id, defaultValue } = formField;

                        newFormFields[id] = defaultValue
                    }
                }
    )

    return newFormFields;
}