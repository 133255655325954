import React, { useEffect, useState } from "react";
import AppointmentSection from "components/Appointments/AppointmentSection";
import AppointmentInfo from "components/Appointments/AppointmentInfo";
import AttachmentList from "components/Appointments/AttachmentList";
import CollateralList from "components/Collateral/CollateralList";
import MessageList from "components/Appointments/MessageList";
import TaskList from "components/Appointments/TaskList";
import { Container, Row, Col } from "react-bootstrap";
// import { Button } from "antd";
// import { QrcodeOutlined } from "@ant-design/icons";
// import { BsFillCameraVideoFill } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

// import { MapOutlined } from '@material-ui/icons';

import { PUBLIC_BASE_URL } from "api/config";
import { fetchAppointmentRequest } from "redux/actions/appointment";
import { setPageTitle, setBreadcrumbsText } from "redux/actions/ui";
import { startTelehealthMeeting } from "redux/actions/zoom";

import { useTranslation } from "react-i18next";
import FormList from "components/Appointments/FormList";
// import QRCodeModal from "components/Appointments/Modals/QRCodeModal";
// import MapModal from "components/Appointments/Modals/MapModal";
import { useAppSelector } from "./../../../hooks/use-app-selector";
// import { APPOINTMENTS_PERMISSIONS } from "redux/types/permissions-types/appointments-permissions";
import {getPublicSetting} from "api/public/settings";
import { Card } from "antd";
import FeedBack from "components/Appointments/FeedBack";
import { UPCOMING } from "constants/appointment-constants";

function View(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const { isLoading, data: appointment, error } = useSelector(
    (state) => state.appointment
  );
  // const { isTeleHealthButtonLoading } = useSelector((state) => state.zoom);
  const { messages } = appointment;

  // const [qrCodeModalVisibility, setQrCodeModalVisibility] = useState(false);
  // const [mapModalVisibility, setMapModalVisibility] = useState(false);
  // const [mapValue, setMapValue] = useState();
  
  // const [qrCodeImg, setQrCodeImg] = useState(null);

  const [taskId, setTaskId] = useState("");
  const [refreshTask, setRefreshTask] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await getPublicSetting({
  //       category: 'TENANT',
  //       dataType: 'FREE_TEXT',
  //       name: 'MAP',
  //       defaultValue: ''
  //     })
  //     setMapValue(res?.data?.value);

  //   }
  //   fetchData();
  // }, []);


  useEffect(() => {
    dispatch(fetchAppointmentRequest(id));
    dispatch(setBreadcrumbsText(appointment.appointmentType));
    dispatch(setPageTitle(" "))

    // if (modulePermissions.includes("appointment:telehealth")) {
    //     dispatch(setPageTitle(appointment.appointmentType))
    // } else {
    //     dispatch(setPageTitle(" "))
    // }

    return () => {
      dispatch(setPageTitle(""));
      dispatch(setBreadcrumbsText(""));
    };
  }, [id, dispatch, appointment.appointmentType]);

  // const onStartAppointmentClick = () => {
  //   dispatch(startTelehealthMeeting(appointment.id));
  // };

  // const showQRCode = () => {
  //   const qrCode = `${PUBLIC_BASE_URL}/qrcode/${appointment.qrCodeValue}`;
  //   setQrCodeImg(qrCode);
  //   setQrCodeModalVisibility(true);
  // };

  // const modulePermissions = useAppSelector(
  //   (state) => state.permissions.permissions.Appointments
  // );

  return (
    <>
      {/* <QRCodeModal
        title={`${appointment.appointmentType} ${t("label.qr_code")}`}
        visible={qrCodeImg && qrCodeModalVisibility}
        onClose={() => {
          setQrCodeModalVisibility(false);
          setQrCodeImg(null);
        }}
        qrCode={qrCodeImg}
        qrCodeValue={appointment.qrCodeValue}
      />


 {mapModalVisibility && <MapModal
        title={`${appointment.appointmentType}`}
        src={mapValue}
        visible={ mapModalVisibility}
        onClose={() => {
          console.log('close')
          setMapModalVisibility(false);
        }}
      />
      } */}
      <Card style={{borderRadius: 10}} bodyStyle={{padding: 3}}>
        <Container fluid>
          <Row>
            {/* <Permission permissions={modulePermissions} permissionKey={APPOINTMENTS_PERMISSIONS['appointment:telehealth']}> */}
            {/* {modulePermissions.includes("appointment:telehealth") ? (
            <>
              {appointment.attendanceType === "TELEHEALTH" && appointment.status  === 'CHECKED_IN' && (
                <Col xs={12} md={6} style={{ marginBottom: "20px" }}>
                  <Button
                    className="Floating-Call-Button mb-4"
                    type="primary"
                    onClick={() => onStartAppointmentClick()}
                    loading={isTeleHealthButtonLoading}
                  >
                    <BsFillCameraVideoFill
                      style={{ marginTop: "-5px" }}
                      size={"2em"}
                    />
                    &nbsp;&nbsp;{t("label.start_telehealth")}
                  </Button>
                </Col>
              )}
            </>
          ) : <Col xs={12} md={6}>
                <h1 className='fivef-pageTitle mt-3 ml-0' style={{ fontSize: '3.5rem' }}>
                    {appointment.appointmentType}
                </h1>
            </Col>} */}
            {/* </Permission> */}
            {/* <Col
            className="Contact-Cancellation"
            xs={12}
            md={6}
            // md={appointment.attendanceType === "TELEHEALTH" && modulePermissions.includes("appointment:telehealth") ? 6 : 12}
          >
            { !mapValue  &&  <Button
              type="default"
              className="mb-4"
              onClick={showQRCode}
              icon={
                <QrcodeOutlined style={{ position: "relative", top: "-4px" }} />
              }
            >
              {t("label.scan_qr_code")}
            </Button>}

            {mapValue  &&  <Button
              type="default"
              className="mb-4"
              onClick={() => { setMapModalVisibility(true);}}
              icon={
               <MapOutlined style={{ fontSize: '4rem' }} />
              }
            >
              Show Map 
            </Button>}
            <h3 className="Appointment-Detail-Title">
              For Cancellations contact:{" "}
              <a href={"tel:" + appointment.locationCancelContactNumber ?? appointment.locationContactNumber}>
                <strong>{appointment.locationCancelContactNumber ?? appointment.locationContactNumber}</strong>
              </a>
            </h3>
          </Col> */}
          </Row>
          <AppointmentSection
          // title={t("label.appointment_info")}
          >
            <AppointmentInfo appointment={appointment} setIsVisible={setIsVisible} />
          </AppointmentSection>
          {
            !isVisible ? <>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} flex={1}>
                  <AppointmentSection title={t("label.task_plural")}>
                    <TaskList
                      setTaskId={setTaskId}
                      refreshTask={refreshTask}
                      setRefreshTask={setRefreshTask}
                      appointmentId={id}
                    />
                  </AppointmentSection>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} flex={1}>
                  <AppointmentSection title={t("label.message_plural")} isMessageCard={true}>
                    <MessageList messages={messages} appointmentId={id} />
                  </AppointmentSection>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} flex={1}>
                  <AppointmentSection title={t("label.collateral_plural")}>
                    <CollateralList
                      collaterals={appointment.collaterals}
                      appointmentId={id}
                    />
                  </AppointmentSection>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <AppointmentSection title={t("label.forms")}>
                    <FormList
                      appointment={appointment}
                      attachments={appointment.attachments}
                    />
                  </AppointmentSection>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                  <AppointmentSection title={t("label.attachment_plural")}>
                    <AttachmentList
                      appointment={appointment}
                      attachments={appointment.attachments}
                      taskId={taskId}
                      setTaskId={setTaskId}
                      setRefreshTask={setRefreshTask}
                    />
                  </AppointmentSection>
                </Col>
              </Row>
            </> : null
          }

        </Container>
      </Card>
      {props?.span === UPCOMING && <FeedBack/>}
    </>
  );
}

export default View;
