import React from 'react';
import { Modal } from 'antd';

const QRCodeModal = props => {
    const { title, visible, onClose, qrCode, qrCodeValue } = props;

    return (
        <Modal
            title={title}
            className='text-center'
            visible={visible}
            footer={[]}
            onCancel={onClose}
        >
            <img src={qrCode} />
            <h1>{qrCodeValue}</h1>
        </Modal>
    )
}

export default QRCodeModal;