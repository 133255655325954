import React, {FunctionComponent, useCallback} from "react";
import {useAppSelector} from "../../../hooks/use-app-selector";
import {Modal} from 'antd';
import styled from "styled-components";
import Interweave from "interweave";

interface UpcomingAppointmentExtraOptions {

}

const UpcomingAppointmentExtraOption = styled.span`
    cursor: pointer;

    &:hover {
        color: #0170FE;
    }
`;


const UpcomingAppointmentExtraOptions: FunctionComponent<UpcomingAppointmentExtraOptions> = (props) => {
    const options = useAppSelector(state => state.tenant.settings.upcomingAppointmentsExtraOptions ? JSON.parse(state.tenant.settings.upcomingAppointmentsExtraOptions) : []);
    const handleOptionSelect = useCallback(({type, modalType, title, content}) => {
        switch (type) {
            case "modal":
                return Modal[modalType]({
                    title,
                    content: <Interweave content={content} />
                })
        }
    }, [])

    return <><br/>{options && options.length > 0 ? options.map((option) =>  <div><UpcomingAppointmentExtraOption onClick={() => handleOptionSelect(option)}>{option.label}</UpcomingAppointmentExtraOption></div>) : null}</>
}

export default UpcomingAppointmentExtraOptions;