import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import styles from "./LoginModule.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { useAppSelector } from "./../../hooks/use-app-selector";
import { AUTHENTICATION_TYPES } from "redux/types/tenant-types";
import MobileAuthenticationComponent from "./components/MobileAuthenticationComponent";
import { message } from 'antd';
import { getBrandingLogoByTenant } from 'api/branding';
import LoginMessage from "../../components/LoginMessage";
import {DEFAULT_LOGO, POWERED_BY_LOGO} from "../../api/config";

interface LoginModuleProps {
  keycloak: any;
}

const LoginModule: FunctionComponent<LoginModuleProps> = ({ keycloak }) => {
  const login = () => keycloak.login();
  const tenant:any = useAppSelector((state) => state.tenant);

  const { branding, uniqueId } = tenant;

  const [currentLogo, setCurrentLogo] = useState(DEFAULT_LOGO);

  useEffect(() => {
      if (branding.tenantUniqueId) {
          setCurrentLogo(getBrandingLogoByTenant(branding.tenantUniqueId));
      } else {
          setCurrentLogo(DEFAULT_LOGO)
      }
  }, [branding.tenantUniqueId]);

  useEffect(() => {
    // Clear out messages
    message.destroy();
  }, [])

    return (
        <>
            <Container className={styles["Landing-Container"]}>
                <div className={styles["Main-Header"]}>
                    <Row>
                        <Col>
                            <img className={styles["Logo"]} src={currentLogo} onError={() => {
                                setCurrentLogo(DEFAULT_LOGO)
                            }}/>
                        </Col>
                    </Row>
                </div>
                <div className={styles.Content}>
                    <div className={styles["Content-Header"]}>
                        <Row>
                            <Col>
                                <h2>{tenant?.branding?.page?.appTitle || 'Digital Patient Journey'}</h2>
                            </Col>
                        </Row>
                    </div>
                    <div className={styles["Main-Content"]}>
                        <>
                            {tenant.authenticationType?.includes(
                                AUTHENTICATION_TYPES.MOBILE
                            ) ? (
                                <MobileAuthenticationComponent/>
                            ) : null}
                            {tenant.authenticationType?.includes(
                                AUTHENTICATION_TYPES.FIVE_FACES_SSO
                            ) ? (
                                <Row>
                                    <Col>
                                        <Button type="primary" onClick={login}>
                                            Sign In with Five Faces SSO
                                        </Button>
                                    </Col>
                                </Row>
                            ) : null}
                        </>
                    </div>
                </div>
                <LoginMessage/>
            </Container>
            <div className='PoweredBy'>
                <div className='text-right'>
                    <small>
                        <a href='https://fivefaces.com.au' target="_blank">
                            <img
                                src={POWERED_BY_LOGO}
                                alt='Five Faces Logo'
                                style={{height: '25px', width: 'auto'}}
                            />
                        </a>
                    </small>
                </div>
            </div>
        </>
    );
};

export default LoginModule;
