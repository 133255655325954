import React from 'react';
import { Avatar } from 'antd';
import { Row, Col, Container } from 'react-bootstrap';
import { MailOutline } from '@material-ui/icons';

import { useDispatch } from 'react-redux';
import {
    setNotificationAsRead,
} from 'redux/actions/notifications';
import parser from 'html-react-parser';
import {
    setNotificationsVisibility,
} from 'redux/actions/ui';
import { useHistory } from 'react-router';

const NotificationItem = ({ key, id, notification }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClick = function() {
        dispatch(setNotificationAsRead(notification.id));
        dispatch(setNotificationsVisibility(false));
        if (notification.appointmentMessage) {
            history.push(`/inbox/${notification.appointmentMessage.id}`);
        } else {
            if (notification.span === 'UPCOMING') {
                history.push(`/upcoming-appointments/${notification.appointment.id}`);
            } else {
                history.push(`/past-appointments/${notification.appointment.id}`);
            }
        }
    }

    const newMessageHTML = notification.message?.replaceAll(/<p>|<\/p>/g, '') || null;
    return (
        <div className={`Notification-Item ${notification.status === 'UNREAD' &&  'Unread-Notification' }`}
            onClick={handleClick}>
            <Container

                fluid>
                <Row>
                    <Col xs={2} md={2}>
                        <Avatar
                            size='large'
                            icon={<MailOutline />}
                            style={{
                                backgroundColor: '#74bbf8'
                            }}
                        />
                    </Col>
                    <Col xs={10} md={10}>
                        <h4 className='Message-Title'>{notification.subject}</h4>
                        <h5 className='Message-Text'>{newMessageHTML && parser(newMessageHTML)}</h5>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NotificationItem;