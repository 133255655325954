import { API_BASE_URL } from './config';
import { createAxios } from './axios';

const patientsApi = createAxios({
    baseURL: `${API_BASE_URL}/patients`
});

export const deletePatient = (patientId: string) => {
    return patientsApi.delete(`/${patientId}`);
}

export const patientDetailsChanged = (payload: any) => {
    return patientsApi.post(`/details-changed`, payload);
}

export const updatePreferences = (payload: any) => {
    return patientsApi.post(`/preference`, payload);
}