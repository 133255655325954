import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Badge, Button, Card } from 'antd';
import { FcMindMap, FcOvertime, FcPhone } from 'react-icons/fc';
import { MdPhone } from 'react-icons/md';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getPublicSetting } from 'api/public/settings';
import { QrcodeOutlined } from '@ant-design/icons';
import { PUBLIC_BASE_URL } from 'api/config';
import { MapOutlined } from '@material-ui/icons';
import { useAppSelector } from 'hooks/use-app-selector';
import { useDispatch, useSelector } from 'react-redux';
// import { BsFillCameraVideoFill } from 'react-icons/bs';
import QRCodeModal from './Modals/QRCodeModal';
import MapModal from './Modals/MapModal';
import { startTelehealthMeeting } from 'redux/actions/zoom';
import {
  createOnlineMeeting,
  deleteOnlineMeeting,
  getOnlineMeeting,
  updateOnlineMeetingDetails,
  getSettings,
} from "../../api/unmanned";
import TeamsChat from "./components/TeamsChat";

function AppointmentInfo({ appointment, setIsVisible }) {
  const configProperties = {
    ENDPOINT: '',
    ACCESS_KEY: '',
    CALENDAR_USER_ID: ''
  };

  const [isTeamsCalleeVisible, setTeamsCalleeVisibility] = useState(true);
  const [isTeamsCalleeDisabled, setTeamsCalleeDisability] = useState(true);
  const [activeCallee, setActiveCallee] = useState('');
  const [config, setConfig] = useState(configProperties);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await getSettings();
      console.log("get settings repsonse ", JSON.stringify(response))
      mapSettingsToConfig(response.data);
    } catch (err) {
      console.error('Error occurred while trying to fetch the configurations.');
    }
  };

  const mapSettingsToConfig = (settings) => {
    let newConfig = {};
    if (settings.length > 0) {
      for (let i = 0; i < settings.length; i++) {
        newConfig[`${settings[i].name}`] = settings[i].value;
      }
      setConfig(newConfig);
    }
  };
























  const [items, setItems] = useState([]);
  const { t } = useTranslation();

  const [mapValue, setMapValue] = useState();
  const [qrCodeImg, setQrCodeImg] = useState(null);
  const [qrCodeModalVisibility, setQrCodeModalVisibility] = useState(false);
  const [mapModalVisibility, setMapModalVisibility] = useState(false);
  const { isTeleHealthButtonLoading } = useSelector((state) => state.zoom);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const res = await getPublicSetting({
        category: 'TENANT',
        dataType: 'FREE_TEXT',
        name: 'MAP',
        defaultValue: ''
      })
      setMapValue(res?.data?.value);

    }
    fetchData();
  }, []);

  const showQRCode = () => {
    const qrCode = `${PUBLIC_BASE_URL}/qrcode/${appointment.qrCodeValue}`;
    setQrCodeImg(qrCode);
    setQrCodeModalVisibility(true);
  };

  const modulePermissions = useAppSelector(
    (state) => state.permissions.permissions.Appointments
  );

  const onStartAppointmentClick = () => {
    dispatch(startTelehealthMeeting(appointment.id));
  };

  const onStartTeamsAppointmentClick = () => {
    setTeamsCalleeVisibility(false);
    setIsVisible(true)
    setActiveCallee('Reception');
  };

  useEffect(() => {
    setItems([
      {
        heading: t('label.date_time'),
        // detailText: <>{moment(appointment.appointmentDate).format("LL")} {moment().set('hour', 8).format('h A')}</>,
        detailText: moment(appointment.appointmentDateTime).format(
          'DD MMM YYYY HH:mm a'
        ),
        icon: <FcOvertime style={{ width: 48, height: 48 }} />,
      },
      {
        heading: t('label.clinic'),
        detailText: appointment.locationName,
        icon: <FcMindMap style={{ width: 48, height: 48 }} />,
      },
      {
        heading: t('label.phone_number'),
        detailText: appointment.locationContactNumber ?? '07 3232 7594',
        icon: <MdPhone style={{ color: '#fff', width: 48, height: 48 }} />,
      },
    ]);
  }, [appointment]);

  return (
    <>
      {config.ACCESS_KEY
        && config.ENDPOINT
        && config.CALENDAR_USER_ID
        && <TeamsChat
          visible={!isTeamsCalleeVisible}
          activeCallee={activeCallee}
          connectionStringEndpointUrl={config.ENDPOINT}
          accessKey={config.ACCESS_KEY}
          afterTeamsChatInitialised={() => {
            setTeamsCalleeDisability(false);
          }}
          calendarUserId={config.CALENDAR_USER_ID}
          callHangupTimerEnabled={true}
          fetchOnlineMeetingRequest={getOnlineMeeting}
          createOnlineMeetingRequest={createOnlineMeeting}
          deleteOnlineMeetingRequest={deleteOnlineMeeting}
          updateOnlineMeetingRequest={updateOnlineMeetingDetails}
          onTeamsChatInitialiseFailure={() => {
            setTeamsCalleeVisibility(true);
          }}
        />}
      {isTeamsCalleeVisible &&
        <>
    <QRCodeModal
        title={`${appointment.appointmentType} ${t("label.qr_code")}`}
        visible={qrCodeImg && qrCodeModalVisibility}
        onClose={() => {
          setQrCodeModalVisibility(false);
          setQrCodeImg(null);
        }}
        qrCode={qrCodeImg}
        qrCodeValue={appointment.qrCodeValue}
      />


 {mapModalVisibility && <MapModal
        title={`${appointment.appointmentType}`}
        src={mapValue}
        visible={ mapModalVisibility}
        onClose={() => {
          console.log('close')
          setMapModalVisibility(false);
        }}
      />
      }
      {/* <Card className="Appointment-Detail-Card Card-Grids Appointment-Card"> */}
      <Container className='Appointment-Info-Container' fluid>
        <Row>
          <Col xs={5} lg={6} md={6} xl={4} className="pr-0">
            <Card className='Card-Gradient-Primary' bodyStyle={{padding: 18}} style={{height: 200}}>
              <Container fluid>
                <Row>
                  <Col>
                    <h4 className='Appointment-Detail-Text'>
                      <span>
                        {`${moment(appointment.appointmentDateTime).format("hh:mm a").toString()}`}
                      </span><br />
                    </h4>
                    <h4 className='Appointment-Detail-Text'>
                      <span>{`${moment(appointment.appointmentDateTime).format("dddd").toString()}`}</span><br className='visible-xs' />{' '}
                      <span className='h3 font-weight-bold'>{`${moment(appointment.appointmentDateTime).format("DD").toString()}`}</span><br className='visible-xs' />{' '}
                      {`${moment(appointment.appointmentDateTime).format("MMM").toString()} 
                     ${moment(appointment.appointmentDateTime).format("YYYY").toString()}`}
                    </h4>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
          <Col
            // className="Contact-Cancellation"
            xs={7}
            lg={6}
            md={6}
            xl={4}
            // md={appointment.attendanceType === "TELEHEALTH" && modulePermissions.includes("appointment:telehealth") ? 6 : 12}
          >
            <h3 className='Appointment-Detail-Info'>{appointment.appointmentType}</h3>
            <h4>{appointment.locationName}</h4>
            <h4 className="mt-1">{appointment?.facility?.name}</h4>          
              <Badge
                className={`Appointment-Details-Badge ${appointment.status?.includes('REFERRAL') ? 'green_bg': ''}`}
                count={appointment.status?.replaceAll('_', ' ')}
                size={"default"}
              /><br />
            {modulePermissions.includes("appointment:telehealth") ? (
                <>
                  {appointment.attendanceType === "TELEHEALTH"  && (
                    // <Col xs={12} md={6} style={{ marginBottom: "20px" }}>
                    <>
                      {/*<Button
                        className="Floating-Call-Button mb-4 mt-2"
                        type="primary"
                        onClick={() => onStartTeamsAppointmentClick()}
                        loading={!isTeamsCalleeVisible}
                      >
                        {"Start Telehealth"}
                      </Button>
                      <br/>*/}
                       <Button
                        className="Floating-Call-Button mb-4 mt-2"
                        type="primary"
                        onClick={() => onStartAppointmentClick()}
                        loading={isTeleHealthButtonLoading}
                      >
                         {"Start Telehealth"}
                      </Button>
                      <br/>
                    </>
                    // </Col>
                  )}
                </>
          ) : 
          // <Col xs={12} md={6}>
                <h1 className='fivef-pageTitle mt-3 ml-0' style={{ fontSize: '3.5rem' }}>
                    {appointment.appointmentType}
                </h1>
            // </Col>
            }
            { !mapValue  && !(appointment.attendanceType === "TELEHEALTH" && appointment.status  === 'CONFIRMED') && (
            <Button
              type="default"
              className="mb-4 QR-Scan-Button"
              style={{ padding: 1 }}
              onClick={showQRCode}
              icon={
                <QrcodeOutlined style={{ position: "relative", top: "-2px" }} />
              }
            >
              {t("label.scan_qr_code")}
            </Button>)}

            {mapValue  &&  <><br/><Button
              type="default"
              className="mb-4"
              onClick={() => { setMapModalVisibility(true);}}
              icon={
               <MapOutlined style={{ fontSize: '4rem' }} />
              }
            >
              Show Map 
            </Button></>}
            <h4>
              To cancel call{" "}
              <a href={"tel:" + appointment.locationCancelContactNumber ?? appointment.locationContactNumber}>
                <strong>{appointment.locationCancelContactNumber ?? appointment.locationContactNumber}</strong>
              </a>
            </h4>
          </Col>
          {/* {items &&
            items.length > 0 &&
            items.map((item, i) => (
              <Col key={i} xs={12} lg={6} xl={4}>
                <Card key={i} className='Card-Gradient-Primary'>
                  <Container fluid>
                    <Row>
                      <Col xs={9} md={10} lg={10}>
                        <h4 className='Appointment-Detail-Title'>
                          <strong className='text-uppercase'>
                            {item.heading}
                          </strong>
                        </h4>
                        <h4 className='Appointment-Detail-Text'>
                          {item.detailText}
                        </h4>
                      </Col>
                      <Col xs={3} md={2} lg={2}>
                        {item.icon}
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </Col>
            ))} */}
        </Row>
      </Container>
        </>
      }
      {/* </Card> */}
    </>
  );
}

export default AppointmentInfo;
