import React from 'react';
import moment from "moment";
import {
    Apartment,
    Assignment,
    AssignmentInd,
    RadioButtonChecked,
    Schedule,
    Today,
    CheckCircle,
} from '@material-ui/icons';
import {
    AiOutlineEllipsis,
    RiCloseCircleLine,
    IoMdTimer
} from 'react-icons/all';
import { Badge, Card } from "antd";
import { Container, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import {EllipsisOutlined} from "@ant-design/icons";
import UpcomingAppointmentExtraOptions from "./components/UpcomingAppointmentExtraOptions";

const AppointmentCard = ({ ActionLayout, item, span }) => {
    const { t } = useTranslation();

    const returnStatusTemplate = status => {
        if (status === 'REQUEST_RESCHEDULE') {
            return <IoMdTimer className='Appointment-Icon' fontSize="large" />
        }

        if (status === 'UNCONFIRMED') {
            return <RadioButtonChecked className='Appointment-Icon' fontSize="small" />
        }

        if (status === 'CONFIRMED') {
            return <CheckCircle fontSize="small" style={{ color: "#4caf50" }} />
        }

        if (status === 'DECLINED') {
            return <RiCloseCircleLine className='Appointment-Icon' fontSize='small' style={{ fontSize: '2.2rem', color: '#f44336' }} />
        }
    };

    return (
        <Card className="Appointment-Card" actions={ActionLayout}>
            <Container fluid>
                {/* <Row>
                    <Col xs={8}>
                        <h4 className="Appointment-Booking-Details">
                            <Today className='Appointment-Icon' fontSize="small" />{" "}
                            {moment(item.appointmentDateTime).format("DD MMMM YYYY").toString()}
                        </h4>
                    </Col>
                    <Col xs={4} className='text-right'>
                        <h4 className="Appointment-Booking-Details">
                            <Schedule className='Appointment-Icon' fontSize="small" />{" "}
                            {moment(item.appointmentDateTime).format("hh:mm A").toString()}
                        </h4>
                    </Col>
                </Row><hr/> */}
                <Row>
                    <Col xs={2} md={4} lg={4}>
                        <div className='Appointment-Date-Container'>
                            <p>
                                <span>
                                    {`
                    ${moment(item.appointmentDateTime).format("hh:mm a").toString()}
                    ${moment(item.appointmentDateTime).format("ddd").toString()}
                    `}
                                </span><br />
                                <span className='h3 font-weight-bold'>{`${moment(item.appointmentDateTime).format("DD").toString()}` }</span><br />
                                {`${moment(item.appointmentDateTime).format("MMM").toString()} 
                     ${moment(item.appointmentDateTime).format("YYYY").toString()}`}
                            </p>
                        </div>
                    </Col>
                    <Col
                        xs={{
                            // offset: 4,
                            // span: 8
                            span: 12
                        }}
                        md={{
                            // offset: 4,
                            // span: 8
                            span: 12
                        }}
                        lg={{
                            // offset: 3,
                            // span: 9
                            span: 12
                        }}
                    >
                        {/* <h6 className='text-muted font-weight-bold text-uppercase Appointment-Type-Title'>{(`${span} APPOINTMENT` === 'UPCOMING APPOINTMENT' ? t('label.upcoming_appointments') : t('label.past_appointments'))}</h6> */}
                        <div style={{height: 75}}>
                            <h4 className="Appointment-Booking-Title">{item.appointmentType}</h4>
                            {item.locationName && <h4 className="Appointment-Booking-Details Location mt-1 mb-2">
                                {/* <Apartment className='Appointment-Icon' fontSize="small" />{" "} */}
                                {item.locationName}
                            </h4>}
                            <h4 className="Appointment-Booking-Details Location mt-1 mb-2">{item?.facility?.name}</h4>
                        </div>
                        <Badge
                            className={`Appointment-Badge ${item.status?.includes('REFERRAL') ? 'green_bg': ''}`}
                            //count={item.attendanceType}
                            count={item.status?.replaceAll('_', ' ')}
                            size={"small"}
                        />
                        {/* {span !== 'PAST' && <h4 className="Appointment-Booking-Details">
                            {returnStatusTemplate(item.status)}&nbsp;
                            {item.status?.replaceAll('_', ' ')}
                        </h4>} */}
                        {span !== 'PAST' ? <UpcomingAppointmentExtraOptions /> : null}
                    </Col>
                </Row>
            </Container>
        </Card>
    )
};

export default AppointmentCard;
