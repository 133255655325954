import React, {useState, FunctionComponent, useEffect} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import {Col, Row} from "react-bootstrap";
import OtpInput from "react-otp-input";
import {confirm, login} from 'api/login';
import {setToken} from 'api/token'
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AUTH_ACTIONS} from "../../../../redux/types/auth-types";
import Cookies from 'js-cookie';
import {TOKEN_KEY, TOKEN_NAME} from '../../../../constants/index';
import { useTranslation } from 'react-i18next';
import {message, Statistic } from 'antd';
import createBrowserHistory from 'history/createBrowserHistory';
import { signUpMobileOTPCreate, signUpUserConfirm } from "api/signup";
// interface OTPComponentProps {
//     length?: number;
//     mobileNumber?: string;
//     signUpOtpSuccess?: (opt: any) => void;
//     confirmRegistration?: boolean;
//     uniqueId?: string;
// }

const { Countdown } = Statistic;
const RESEND_OTP_COOLDOWN = 300000

const OTPComponent = ({length = 6, mobileNumber, signUpOtpSuccess, confirmRegistration, uniqueId}) => {
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [resendOtpOnCooldown, setResendOtpOnCooldown] = useState(false)
    const [error, setError] = useState(null);
    const [currentUrl, setCurrentUrl] = useState('');
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const browserHistory = createBrowserHistory({forceRefresh:true});

    useEffect(() => {
        setCurrentUrl(location?.pathname.replace('/',''));
    },[location])

    useEffect(() => {
        if (otp.length === length) {
            setLoading(true);
            let payload = {contactNumber: mobileNumber, code: otp};

            if (currentUrl === 'signup' && !confirmRegistration) {
                signUpOtpSuccess(otp)
                setLoading(false);
                setOtp('');
            } else if (currentUrl === 'signup' && confirmRegistration) {
                const confirmPayload = {
                    code: otp,
                    uniqueId,
                }

                signUpUserConfirm(confirmPayload)
                    .then(res => {
                        message.success('Signed Up Successfully.')
                        browserHistory.push('/login');
                    }).catch(err => {
                        console.log(err);
                    });
            } else {
                confirm(payload).then(e => {
                    let token = e.data.data.token;
                    let tokenKey = e.data.data.tokenKey

                    dispatch({
                        type: AUTH_ACTIONS.SET_TOKEN,
                        payload: {token}
                    })
                    // localStorage.setItem('dpjTkn',token)

                    // if (Cookies.get(TOKEN_NAME)) {
                    //     Cookies.remove(TOKEN_NAME)
                    // }

                    console.log('Cookies Set ', token);

                    Cookies.set(TOKEN_NAME, token);

                    // if (Cookies.get(TOKEN_KEY)) {
                    //     Cookies.remove(TOKEN_KEY)
                    // }

                    Cookies.set(TOKEN_KEY, tokenKey);

                    sessionStorage.setItem(TOKEN_NAME, token);

                    setToken(token)
                    // @todo need to be able to route depending on permissions
                    history.push('/upcoming-appointments')
                }).catch(error => {
                    setLoading(false);
                    setOtp("");
                })
            }
        }
    }, [otp]);

    return (
        <Row className="justify-content-center">
            <Col xs={12} className="my-5">
                {loading ? (
                    <Spin indicator={<LoadingOutlined style={{fontSize: 36}} spin/>}/>
                ) : (
                    <>
                        <p className="mt-5">
                            Please enter the OTP sent to your mobile number
                        </p>
                        <OtpInput
                            isInputNum={true}
                            shouldAutoFocus={true}
                            onChange={(value) => {
                                setOtp(value);
                            }}
                            value={otp}
                            numInputs={6}
                            separator={<span> </span>}
                            containerStyle={{
                                justifyContent: "center",
                            }}
                            inputStyle={{
                                width: "45px",
                                height: "45px",
                                border: "0.2px solid grey",
                                borderRadius: "10px",
                                boxShadow: "0px 4px 5px 0px rgb(55 101 246 / 20%)",
                                fontSize: "2.25rem",
                                margin: "5px",
                            }}
                        />
                        <p className="mt-5">
                            {resendOtpOnCooldown ? <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}><span>You can resend an OTP after</span><Countdown value={Date.now() + RESEND_OTP_COOLDOWN} onFinish={() => setResendOtpOnCooldown(false)} /><span>seconds</span></div> : <>Did not get one? <span className="text-primary" style={{
                                cursor: "pointer"
                            }} onClick={() => {
                                if(currentUrl === 'signup') {
                                    const payload = {
                                        contactNumber: mobileNumber,
                                    };
                                    signUpMobileOTPCreate(payload).then(() => {
                                        setResendOtpOnCooldown(true)
                                    }).catch(err => message.error(t("message.invalid_otp")))
                                } else {
                                    login({
                                        contactNumber: mobileNumber,
                                    }).then(() => {
                                        setResendOtpOnCooldown(true)
                                    }).catch(err => message.error(t("message.invalid_otp")))
                                }
                            }}>Tap here to resend</span></>}
                        </p>
                    </>
                )}
            </Col>
        </Row>
    );
};

export default OTPComponent;
