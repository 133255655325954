import { REGISTRATION_TYPE, TenantAction, TenantState, TENANT_ACTIONS } from "redux/types/tenant-types";
import {
    FETCH_APPOINTMENTS_LIST_ERROR,
    FETCH_APPOINTMENTS_LIST_SUCCESS,
} from "./types/appointment";
import {getAppointmentsBySpan} from "../../api/appointments";
import {
    FETCH_TENANT_UPCOMING_APPOINTMENTS_EXTRA_OPTIONS_ERROR,
    FETCH_TENANT_UPCOMING_APPOINTMENTS_EXTRA_OPTIONS_REQUEST, FETCH_TENANT_UPCOMING_APPOINTMENTS_EXTRA_OPTIONS_SUCCESS
} from "./types/tenant";
import { getPublicSetting } from 'api/public/settings';

export const setTenantBranding = (branding:any):TenantAction => {
    let returnAction = {
        type: TENANT_ACTIONS.SET_TENANT_BRANDING,
        payload: {
            branding
        }
    }
    
    return returnAction
}

export const setTenantRegistration = (payload: REGISTRATION_TYPE) => {
    return {
        type: TENANT_ACTIONS.SET_TENANT_REGISTRATION,
        payload
    }
}

export const setTenant = (payload:TenantState):TenantAction => {
    return {
        type: TENANT_ACTIONS.SET_TENANT,
        payload
    }
}

export const fetchTenantUpcomingAppointmentsExtraOptions = () => {
    return async dispatch => {
        try {
            dispatch({
                type: FETCH_TENANT_UPCOMING_APPOINTMENTS_EXTRA_OPTIONS_REQUEST,
            });
            
            const response = await getPublicSetting({
                category: 'TENANT',
                dataType: 'JSON',
                name: 'TENANT_UPCOMING_APPOINTMENTS_EXTRA_OPTIONS'
            })
            
            dispatch({
                type: FETCH_TENANT_UPCOMING_APPOINTMENTS_EXTRA_OPTIONS_SUCCESS,
                payload: response.data.value
            });
        } catch (error) {
            dispatch({
                type: FETCH_TENANT_UPCOMING_APPOINTMENTS_EXTRA_OPTIONS_ERROR,
            });
        }
    }
};