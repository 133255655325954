export enum MessageTypes {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR'
}

export interface LengthObject {
    value: number,
    message: string
}

export interface MatchObject {
    criteria: any,
    message: string
}

export interface ValidationObject {
    type: string,
    message?: string,
    match?: MatchObject,
    min?: LengthObject,
    max?: LengthObject
}

export interface FieldComponentSelect {
    value: any,
    displayText: string
}

export interface FieldComponent {
    id: string,
    label?: string,
    name: string,
    placeholder?: string,
    size: string | null,
    type: string,
    defaultValue: string | null,
    isMaterialDatePicker?: boolean | undefined,
    items?: FieldComponentSelect[],
    props?: Object,
    validation?: ValidationObject[],
    additionalComponent?: any
}