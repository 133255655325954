export const FETCH_APPOINTMENTS_LIST_REQUEST = 'FETCH_APPOINTMENTS_LIST_REQUEST';
export const FETCH_APPOINTMENTS_LIST_SUCCESS = 'FETCH_APPOINTMENTS_LIST_SUCCESS';
export const FETCH_APPOINTMENTS_LIST_ERROR = 'FETCH_APPOINTMENTS_LIST_ERROR';

export const FETCH_APPOINTMENT_REQUEST = "FETCH_APPOINTMENT_REQUEST";
export const FETCH_APPOINTMENT_SUCCESS = "FETCH_APPOINTMENT_SUCCESS";
export const FETCH_APPOINTMENT_ERROR = "FETCH_APPOINTMENT_ERROR";

export const CONFIRM_APPOINTMENT_REQUEST = 'CONFIRM_APPOINTMENT_REQUEST';
export const CONFIRM_APPOINTMENT_SUCCESS = 'CONFIRM_APPOINTMENT_SUCCESS';
export const CONFIRM_APPOINTMENT_ERROR = 'CONFIRM_APPOINTMENT_ERROR';

export const UPLOAD_ATTACHMENT_REQUEST = 'UPLOAD_ATTACHMENT_REQUEST';
export const UPLOAD_ATTACHMENT_SUCCESS = 'UPLOAD_ATTACHMENT_SUCCESS';
export const UPLOAD_ATTACHMENT_ERROR = 'UPLOAD_ATTACHMENT_ERROR';

export const DELETE_ATTACHMENT_REQUEST = 'DELETE_ATTACHMENT_REQUEST';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_ERROR = 'DELETE_ATTACHMENT_ERROR';

export const UPDATE_ATTACHMENT_DESCRIPTION_REQUEST = 'UPDATE_ATTACHMENT_DESCRIPTION_REQUEST';
export const UPDATE_ATTACHMENT_DESCRIPTION_SUCCESS = 'UPDATE_ATTACHMENT_DESCRIPTION_SUCCESS';
export const UPDATE_ATTACHMENT_DESCRIPTION_ERROR = 'UPDATE_ATTACHMENT_DESCRIPTION_ERROR';

export const SET_WILL_TASK_LIST_FETCH = 'SET_WILL_TASK_LIST_FETCH';

export const UPDATE_APPOINTMENTS_PARAMS = 'UPDATE_APPOINTMENTS_PARAMS';
