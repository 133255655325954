
import styled from 'styled-components';

export const VideoContainer = styled.div`
width: 100%;
height: auto;
background: #404040;
display: flex;
flex: 1;
justify-content: center;
align-items: center;
flex-direction: column;

p {
    color: #fff;
}
`;

export const RemoteVideoContainer = styled.div`
width: 70%;
height: auto;
background-color: #363636;
position: relative;

div {
    background-color: #404040;
    min-width: 366px;
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
`;

export const LocalVideoContainer = styled.div`
width: 350px;
min-height: 220px;
border-radius: 10px;
background-color: #363636;
position: absolute;
left: 10px;
bottom: 10px;

div {
    background-color: #363636;
    border-radius: 10px;
    width: 350px;
    min-height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1, 1) !important;
}
`;
