import React, { useEffect, useState } from 'react';
import { Button, Card, List, Modal, Tag } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { Container, Row, Col } from 'react-bootstrap';
import NurseIcon from 'assets/icons/nurse';
import PatientIcon from 'assets/icons/patient';
import { ArrowRightAlt, KeyboardBackspace, Reply } from '@material-ui/icons';
import moment from 'moment';
import { handleResponsive } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReplyModal from 'components/Appointments/Modals/ReplyModal';
import SendMessageModal from './Modals/SendMessageModal';
import { getAppointmentMessagesDataTable } from 'api/messages';
import { useAppSelector } from 'hooks/use-app-selector';

import 'styles/RowHighlights.scss';

const ReplyButton = styled(Button)`
    border: 1px solid #ccc;
    min-height: unset;
    position: absolute;
    right: 0;
    & span {
        font-size: 12px;
        font-weight: 300;
    }
`;

function MessageList({appointmentId}) {
    const [alterMessageDatePosition, setMessageDatePositionAlteration] = useState(false);
    const [currentMessage, setCurrentMessage] = useState({});
    const [isMessagePreviewVisible, setMessagePreviewVisibility] = useState(false);
    const [isReplyModalVisible, setIsReplyModalVisible] = useState(false);
    const [isSendMessageModalVisible, setIsSendMessageModalVisible] = useState(false);
    
    const permissions = useAppSelector(state => state.permissions);

    const [ messages, setMessages ] = useState([]);
    const [ currentPage, setCurrentPage ] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        handleResponsive({
            xsCallback: () => setMessageDatePositionAlteration(true),
            smCallback: () => setMessageDatePositionAlteration(true),
            xlCallback: () => setMessageDatePositionAlteration(false)
        });
        if(!messages?.length) {
            fetchAppointmentMessages(currentPage);
        }
    }, [appointmentId])

    const showReplyModal = (e, message) => {
        e.preventDefault(); 
        e.stopPropagation(); 
        setIsReplyModalVisible(true);
        setCurrentMessage(message);
    }

    const fetchAppointmentMessages = async (page) => {
        const res = await getAppointmentMessagesDataTable({
            appointmentId: appointmentId,
            length: 4,
            page: page,
            sortColumn: 'createdAt',
            sortDirection: 'DESC'
        })
        setMessages(res.data)
    }

    return (
        <>
            { currentMessage && isReplyModalVisible &&
                <ReplyModal 
                    messageId={currentMessage?.replyingToMessage ? currentMessage.replyingToMessage.id : currentMessage?.id }
                    appointmentId={appointmentId}
                    visible={isReplyModalVisible} 
                    subject={(currentMessage.replyingToMessage? 'Re: ' : '') + currentMessage.subject}
                    hide={ () => setIsReplyModalVisible(false) }
                    onSuccess={() => fetchAppointmentMessages(currentPage) }
                />
            }
            { appointmentId &&
                <SendMessageModal
                    appointmentId={appointmentId}
                    visible={isSendMessageModalVisible}
                    hide={() => setIsSendMessageModalVisible(false)}
                    onSuccess={() => fetchAppointmentMessages(currentPage) }
                />
            }
            <Modal
                className='Modal-Message-Details'
                width={700}
                title={(
                    <span>
                        <strong>{t('label.subject')}:</strong>&nbsp;&nbsp;
                        <span style={{ fontWeight: 'normal' }}>{currentMessage.replyingToMessage? 'Re: ': null}{currentMessage.subject}</span>
                    </span>
                )}
                visible={isMessagePreviewVisible}
                footer={false}
                onCancel={() => { 
                    setCurrentMessage({});
                    setMessagePreviewVisibility(false); 
                }}
                centered
                zIndex={500}

            >
                <Container fluid>
                    <Row>
                        <Col className='text-center' 
                            xs={{
                                order: 1,
                                span: 12
                            }} md={{
                                order: 1,
                                span: 12
                            }}
                            style={{
                                padding: '10px 24px'
                            }} 
                        >
                            <h4 style={{ textAlign: 'left' }}><strong>{t('label.message')}:</strong></h4>
                            <h4 style={{ textAlign: 'left' }}>{currentMessage.message}</h4>
                        </Col>
                    </Row>
                </Container><hr/>
                <Container fluid>
                    <Row>
                        <Col 
                            className='text-center' 
                            xs={{
                                order: 2,
                                span: 12
                            }} md={{
                                order: 3,
                                span: 6
                            }}
                            style={{
                                padding: '5px 24px'
                            }}    
                        >
                        </Col>
                        <Col 
                            className='text-center' 
                            xs={{
                                order: 2,
                                span: 12
                            }} md={{
                                order: 3,
                                span: 6
                            }}
                            style={{
                                padding: '5px 24px'
                            }}    
                        >
                            {permissions.permissions.Appointments.includes('appointment:messages:reply') && <Button 
                                className='mb-4'
                                block 
                                onClick={ ()=> setIsReplyModalVisible(true) }
                            >
                                <Reply style={{ marginTop: '-10px' }} /> {t('label.reply')}
                            </Button>}
                        </Col>
                    </Row>
                </Container>
            </Modal>
            <Container className='Appointment-Info-Container' fluid>
                <Card className='Card-Gradient' bodyStyle={{ padding: 12 }}>
                    <Row>
                        <Col>
                            {permissions.permissions.Appointments.includes('appointment:messages:send') && <Button
                                className='Action-Button col-md-12 text-center'
                                type='primary'
                                size='small'
                                onClick={() => setIsSendMessageModalVisible(true)}
                            // icon={<MessageOutlined style={{position: 'relative', top: '-4px'}} />}
                            >
                                {t('label.new_message')}
                            </Button>}
                        </Col>
                    </Row><br />
                    <Row>
                        <Col>
                            <List
                                itemLayout="horizontal"
                                style={{
                                    boxShadow: 'none',
                                }}
                                dataSource={messages?.data}
                                // pagination={{
                                //     total: messages?.recordsTotal,
                                //     pageSize: 4,
                                //     onChange: (page, pageSize) => {
                                //         setCurrentPage(page - 1);
                                //         fetchAppointmentMessages(page - 1);
                                //     }
                                // }}
                                renderItem={(message, i) => (
                                    <List.Item
                                        className={`Inbox-Item ${message.highlight ? 'Row-Highlight' : 'Row-Unhighlight'}`}
                                        actions={
                                            // alterMessageDatePosition ? [] : 
                                            [<span className='MessageList-Item'>{moment(message.createdAt).format("DD MMM hh:mm A")}</span>]
                                        }
                                        onClick={() => {
                                            setCurrentMessage(message);
                                            setMessagePreviewVisibility(true);
                                        }}
                                    >
                                        <List.Item.Meta
                                            // TODO: Remove icons for now and will change later.
                                            // avatar={message.sender === 'STAFF' ? <NurseIcon width={40} height={40} /> : <PatientIcon width={40} height={40} />}
                                            title={<><strong className='ml-4 d-inline-block'>{message.replyingToMessage ? 'Re: ' : null}{message.subject}</strong></>}
                                            description={<>
                                                <div className="ml-4 mb-4 font-weight-bold"
                                                    style={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        WebkitLineClamp: 1,
                                                        overflowY: 'hidden'
                                                        // textOverflow: 'ellipsis', 
                                                        // width: '100%', 
                                                        // overflow: 'hidden', 
                                                        // whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {message.message}<br />
                                                    <span style={{ color: '#1890FF' }}>{t('label.click_view_message')}</span>
                                                    {permissions.permissions.Appointments.includes('appointment:messages:reply') && <ReplyButton onClick={e => showReplyModal(e, message)}><Reply />{t('label.reply')}</ReplyButton>}
                                                </div>
                                                {/* {alterMessageDatePosition ? moment(message.createdAt).format("DD/MM") : "" } */}
                                                <div className='ml-4 Message-Tag'>
                                                    {message.sender === 'STAFF' ?
                                                        <KeyboardBackspace style={{border: '1px solid black', color: 'white', backgroundColor: 'blue'}} fontSize='default' />
                                                        :
                                                        <ArrowRightAlt style={{border: '1px solid black', color: 'white', backgroundColor: 'blue'}} fontSize='default'/>
                                                    }
                                                </div>
                                            </>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
}

export default MessageList;