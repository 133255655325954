import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Empty, message } from 'antd';
import { InputField } from 'components/Controls';
import FileUploader from 'components/Controls/FileUploader';
import ConfirmDialog from 'components/Dialogs/Confirm';
import 'styles/Attachments.scss';
import 'styles/RowHighlights.scss';

import {
    uploadAttachment,
    updateAttachmentDescription,
    deleteAttachment,
    getAttachmentDataTable,
    getAttachmentUrl
} from 'api/attachments';
import { updatePatientTaskStatus } from 'api/patientTasks';

import { Container, Row, Col } from 'react-bootstrap';
// import { Attachment, Create, Delete } from '@material-ui/icons';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { downloadURI } from 'helpers/utils';

import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';
import moment from 'moment';

function AttachmentList(props) {
    const { attachments, appointment, taskId, setTaskId, setRefreshTask } = props;
    const [editMode, setEditMode] = useState(false);
    const [isAddEditAttachmentModalVisible, setAddEditAttachmentModalVisible] = useState(false);
    const [isConfirmDeleteDialogVisible, setConfirmDeleteDialogVisibility] = useState(false);
    const [activeAttachment, setActiveAttachment] = useState({});
    const [isUploadLoadingVisible, setUploadLoadingVisibility] = useState(false);
    const [attachmentsList, setAttachmentsList] = useState([]);
    const [isConfirmDeletionButtonLoading, setConfirmDeletionButtonLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if(!attachmentsList?.length && appointment?.id) {
            fetchAppointmentAttachments(0);
        }
    }, [appointment]);

    const fetchAppointmentAttachments = async (page) => {
        const res = await getAttachmentDataTable({
            appointmentId: appointment?.id,
            length: 10,
            page: page,
            sortColumn: 'id',
            sortDirection: 'ASC'
        })
        setAttachmentsList(res.data.data);
    }

    const saveAttachment = async ({ description, file, taskId }) => {
        try {
            setUploadLoadingVisibility(true);

            let formData = new FormData();

            formData.append('description', description);
            formData.append('file', file);
            formData.append('appointmentId', appointment.id);
            taskId && formData.append('patientTaskId', taskId);

            const response = await uploadAttachment(formData);

            let newAttachmentsList = [...attachmentsList];

            const newAttachment = {
                id: response.data.id,
                fileName: response.data.file.originalFilename,
                description: response.data.description
            };

            newAttachmentsList.push(newAttachment);

            setAttachmentsList(newAttachmentsList);

            setUploadLoadingVisibility(false);
            setAddEditAttachmentModalVisible(false);

            if(taskId) {
                updatePatientTaskStatus(taskId, 'COMPLETED')
                .then(res => {
                    message.success('Successfully completed task.');
                    setRefreshTask(1);
                })
            }
            setTaskId('');
            fetchAppointmentAttachments(0);
        } catch (error) {
            console.log(error);
            message.error(t('message.add_attachment_error'));
            setUploadLoadingVisibility(false);
        }
    };

    const updateAttachmentDescriptionRequest = async (id, payload) => {
        try {
            setUploadLoadingVisibility(true);

            delete payload.file;

            const response = await updateAttachmentDescription(id, payload);

            const newAttachmentsList = [...attachmentsList];
            
            const attachmentIndex = newAttachmentsList.findIndex(attachment => attachment.id === id);

            newAttachmentsList[attachmentIndex].description = response.data.description;

            setUploadLoadingVisibility(false);
            setAddEditAttachmentModalVisible(false);
            fetchAppointmentAttachments(0);
        } catch (error) {
            console.log(error);
            message.error(t('message.update_attachment_error'));
            setUploadLoadingVisibility(false);
        }
    };

    const deleteAttachmentRequest = async id => {
        try {

            setConfirmDeletionButtonLoading(true);

            await deleteAttachment(id);

            const newAttachmentsList = [...attachmentsList];

            const filteredList = newAttachmentsList.filter(attachment => attachment.id !== id);

            setConfirmDeletionButtonLoading(false);
            setConfirmDeleteDialogVisibility(false);
            fetchAppointmentAttachments(0);
        } catch (error) {
            console.log(error);
            message.error(t('message.delete_attachment_error'));
            setConfirmDeletionButtonLoading(false);
        }
    };

    // const EditAttachmentButton = attachment => (
    //     <div onClick={() => {
    //         setEditMode(true);
    //         setActiveAttachment(attachment);
    //         setAddEditAttachmentModalVisible(true);
    //     }}>
    //         <span className="d-block d-lg-block font-weight-bold text-primary">{t('label.edit')}</span>
    //     </div>
    // );

    // const DeleteAttachmentButton = attachment => (
    //     <div onClick={() => {
    //         setActiveAttachment(attachment);
    //         setConfirmDeleteDialogVisibility(true);
    //     }}>
    //         <span className="d-block d-lg-block font-weight-bold text-danger">{t('label.delete')}</span>
    //     </div>
    // );

    // const StyledMenuItem = styled(Menu.Item)`
    //     & .anticon {
    //         font-size: 20px !important;
    //     }
    // `;

    // const renderMenus = (attachment) => (
    //     <Menu>
    //         <>
    //             <StyledMenuItem
    //                 key={1}
    //                 //icon={menu.icon}
    //                 onClick={async () => {
    //                     try {
    //                         let url = await getAttachmentUrl(attachment.id);
    //                         if (url.data && attachment?.file?.originalFilename) {
    //                             downloadURI(url.data, attachment?.file?.originalFilename);
    //                         }
    //                     } catch (err) {
    //                         message.error(JSON.stringify(err.toJSON()))
    //                         message.error('Error downloading file.');
    //                     }
    //                 }}
    //             >
    //                 Download
    //             </StyledMenuItem>
    //             <StyledMenuItem
    //                 key={2}
    //                 //icon={menu.icon}
    //                 onClick={() => {
    //                     setEditMode(true);
    //                     setActiveAttachment(attachment);
    //                     setAddEditAttachmentModalVisible(true);
    //                 }}
    //             >
    //                 Edit
    //             </StyledMenuItem>
    //             <StyledMenuItem
    //                 key={3}
    //                 //icon={menu.icon}
    //                 onClick={() => {
    //                     setActiveAttachment(attachment);
    //                     setConfirmDeleteDialogVisibility(true);
    //                 }}
    //             >
    //                 Delete
    //             </StyledMenuItem>
    //         </>
    //     </Menu>
    // );

    return (
        <>
            <Modal
                title={`${(editMode ? t('label.edit') : t('label.add'))} ${t('label.attachment')}`}
                visible={isAddEditAttachmentModalVisible || taskId}
                footer={false}
                closable={false}
                destroyOnClose={true}
            >
                <Formik
                    initialValues={{
                        description: (activeAttachment && activeAttachment.description ? activeAttachment.description : ''),
                        file: {}
                    }}
                    validationSchema={Yup.object({
                        description: Yup.string().required('Please provide description'),
                        file: Yup.mixed().required('Please provide an attachment')
                    })}
                    onSubmit={values => {
                        let payload = values;

                        if (editMode) {
                            updateAttachmentDescriptionRequest(activeAttachment.id, payload);
                        } else {
                            payload.appointmentId = appointment.id;
                            if(taskId) {
                                payload.taskId = taskId;
                            }
                            saveAttachment(payload);
                        }
                    }}
                >
                    <Form>  
                        <Container fluid>
                            <Row>
                                <Col xs={{
                                    order: 1,
                                    span: 12
                                }} style={{
                                    marginBottom: '20px'
                                }}>
                                    {!editMode ? <Field
                                        name='file'
                                        component={FileUploader}
                                    /> : (
                                        <>
                                            <label>
                                                {t('label.file_name')}:
                                            </label><br/>
                                            <label>
                                                <strong>{activeAttachment.fileName}</strong>
                                            </label><br/>
                                        </>
                                    )}
                                    <Field
                                        id='description'
                                        name='description'
                                        type='text'
                                        label={t('label.description')}
                                        placeholder='Enter attachment description'
                                        size='large'
                                        className='mb-2'
                                        component={InputField}
                                    />
                                </Col>
                                <Col 
                                    className='text-center' 
                                    xs={{
                                        order: 3,
                                        span: 12
                                    }} md={{
                                        order: 2,
                                        span: 6
                                    }}>
                                        <Button 
                                            className='mb-4' 
                                            onClick={() => { 
                                              setAddEditAttachmentModalVisible(false); 
                                              setTaskId('') 
                                            }} 
                                            disabled={isUploadLoadingVisible}
                                            block>
                                            {t('label.cancel')}
                                        </Button>
                                    </Col>
                                    <Col className='text-center' xs={{
                                        order: 2,
                                        span: 12
                                    }} md={{
                                        order: 3,
                                        span: 6
                                    }}>
                                    <Button 
                                        className='mb-4' 
                                        htmlType='submit' 
                                        type='primary' 
                                        loading={isUploadLoadingVisible}
                                        block>
                                        {t('label.save')}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>    
                    </Form>
                </Formik>
            </Modal>
            <ConfirmDialog
                isDialogVisible={isConfirmDeleteDialogVisible}
                confirmationText={t('message.delete_attachment')}
                isCancelLoading={isConfirmDeletionButtonLoading}
                isConfirmLoading={isConfirmDeletionButtonLoading}
                okText={t('label.confirm')}
                cancelText={t('label.cancel')}
                onConfirm={() => {
                    deleteAttachmentRequest(activeAttachment.id);
                }}
                onCancel={() => {
                    setConfirmDeleteDialogVisibility(false);
                }}
            />
            <Container className='Appointment-Info-Container' fluid>
                <Card className='Card-Gradient' bodyStyle={{ padding: 12 }}>
                    <Row>
                        <Col>
                            <input type='file' className='d-none' />
                            <Button
                                className='Action-Button col-md-12 text-center'
                                type='primary'
                                onClick={() => {
                                    setEditMode(false);
                                    setActiveAttachment({});
                                    setAddEditAttachmentModalVisible(true);
                                }}
                            >
                                + Add new attachment
                            </Button>
                        </Col>
                    </Row><br />
                    {attachmentsList && attachmentsList.length < 1 && (
                        <Row>
                            <Col className='text-center mb-4 mt-4'>
                                <Empty description={t('message.no_attachment')} />
                            </Col>
                        </Row>
                    )}
                    {attachmentsList && attachmentsList.length > 0 && attachmentsList.map((attachment, i) => {
                        //const Actions = [EditAttachmentButton(attachment), DeleteAttachmentButton(attachment)]
                        return (
                            // <Card key={i} className='col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6' actions={Actions}>
                            <>
                                {/* <Row className={`Attachment-Details ${i === 1 ? 'Row-Highlight' : 'Row-Unhighlight'}`}> */}
                                <Row className='mb-2'>
                                    <Col xs={6} md={4} lg={4}>
                                        <span className='font-weight-bold'>{moment(attachment.createdAt).format('DD MMM hh:mm A')}</span>
                                    </Col>
                                    <Col xs={6} md={8} lg={8}>
                                        {/* <Attachment fontSize={'large'} /> */}
                                        {/* <h4 className='Attachment-Card-Title'>{attachment.description}</h4> */}
                                        <Button
                                            type='link'
                                            className='Link-Data'
                                            onClick={async () => {
                                                try {
                                                    let url = await getAttachmentUrl(attachment.id);
                                                    if (url.data && attachment?.file?.originalFilename) {
                                                        downloadURI(url.data, attachment?.file?.originalFilename);
                                                    }
                                                } catch (err) {
                                                    message.error(JSON.stringify(err.toJSON()))
                                                    message.error('Error downloading file.');
                                                }
                                            }}
                                        ><span className='float-left text-decoration-none'>{attachment.description}</span></Button>
                                    </Col>
                                    {/* <Col xs={4} md={3} lg={4}>
                                        <Dropdown overlay={renderMenus(attachment)} trigger={['click']}>
                                            <Button type='primary' className='Action-Button float-right' size='small'>Actions</Button>
                                        </Dropdown>
                                    </Col> */}
                                </Row>
                            </>
                        )
                    })}
                </Card>
            </Container>
        </>
    );
}

export default AttachmentList;