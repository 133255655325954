import React from 'react';
import 'styles/Footer.scss';
import {POWERED_BY_LOGO} from "../../api/config";

const Footer = () => {
    return (
      <>
          <div className='text-right' style={{marginTop: '12px'}}>
              <small>
                  <a href='https://fivefaces.com.au' target="_blank">
                      <img
                        src={POWERED_BY_LOGO}
                        alt='Five Faces Logo'
                        style={{height: '25px', width: 'auto'}}
                      />
                  </a>
              </small>
          </div>
      </>
    );
};

export default Footer;