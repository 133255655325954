import { API_BASE_URL } from 'api/config';
import {createAxios} from "./axios";

const appointmentApi = createAxios({
    baseURL: API_BASE_URL + '/appointments'
});

export const getAppointmentsBySpan = (span, params) => {
    return appointmentApi.get(`?span=${span}`, {
        params
    });
};

export const getAppointmentById = id => {
    return appointmentApi.get(`/${id}`);
};

export const updateAppointmentStatus = (payload) => {
    return appointmentApi.patch(`/status`, payload);
};

export const rescheduleAppointmentStatus = (id, payload) => {
    return appointmentApi.post(`/${id}/re-schedule`, payload);
};

export const addAppointment = appointment => {
    return appointmentApi.post('', appointment)
}

export const getAppointmentsDatatable = params => {

    let doctorFilter = null;
    let appointmentTypeFilter = null;
    let statusFilter = null;
    if (params.filters && params.filters.length > 0) {
        doctorFilter = params.filters[3][0];
        appointmentTypeFilter = params.filters[4][0];
        statusFilter = params.filters[5][0];
    }

    return appointmentApi.get('', {
        timeout: 5000,
        params: {
            page: params.page,
            draw: 1,
            start: params.start,
            sortOrder: params.sortOrder,
            sortDirection: params.sortDirection,
            length: params.length,
            search: params.search,
            doctorFilter: doctorFilter,
            appointmentTypeFilter: appointmentTypeFilter,
            statusFilter: statusFilter

        }
    });
}
