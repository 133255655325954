import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
    rescheduleAppointmentStatus
} from 'api/appointments';
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "hooks/use-app-selector";
import {
  fetchAppointmentsBySpan,
  confirmAppointmentStatus,
  declineAppointmentStatus,
  checkedInAppointmentStatus,
  updateAppointmentsParams
} from "redux/actions/appointment";
import { startTelehealthMeeting } from "redux/actions/zoom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { List, Spin, message } from "antd";
import FeedBack from "components/Appointments/FeedBack";
import AppointmentCard from "components/Appointments/AppointmentCard";
import ConfirmationModal from "components/Appointments/Modals/ConfirmationModal";
import DeclineModal from "components/Appointments/Modals/DeclineModal";
import CheckInModal from "components/Appointments/Modals/CheckInModal";
import RescheduleModal from "components/Appointments/Modals/RescheduleModal";
import QRCodeModal from 'components/Appointments/Modals/QRCodeModal';
import {
  UPCOMING,
  PAST,
  UPCOMING_APPOINTMENTS,
  PAST_APPOINTMENTS,
} from "constants/appointment-constants";
import { Check, Clear, CropFree, Info, Telegram } from "@material-ui/icons";
import { AiFillCloseCircle } from "react-icons/all";
import { AppointmentStatuses } from "enums/appointment-statuses";
import { AppointmentAttendanceTypes } from "enums/appointment-attendance-types";
import { PUBLIC_BASE_URL } from 'api/config';
interface AppointmentsModuleProps {
  span: string;
}

const AppointmentsModule: FunctionComponent<AppointmentsModuleProps> = ({
  span,
}) => {
  const [activeAppointmentId, setActiveAppointmentId] = useState(0);
  const appointments = useAppSelector((state) => state.appointment);
  const permissions = useAppSelector((state) => state.permissions.permissions);
  const pageSize = 6;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tenantState = useSelector((state: any) => state.tenant);

  useEffect(() => {
    dispatch(updateAppointmentsParams({
      currentPageNumber: 0,
      length: pageSize,
      span: span,
      sortColumn: "appointmentDateTime",
      sortDirection: span === PAST ? "DESC" : "ASC"
    }));
  },[span]);

  const fetchAppointments = useCallback(
    (page: number) => {
      const sortDirection = span === PAST ? "DESC" : "ASC";
      dispatch(
        fetchAppointmentsBySpan(span, {
          length: pageSize,
          page: page - 1,
          sortDirection: sortDirection,
          sortColumn: "appointmentDateTime",
        })
      );
    },
    [span, dispatch]
  );

  useEffect(() => {
    fetchAppointments(1);
  }, [dispatch, span, fetchAppointments]);

  /** VIEWING APPOINTMENT */
  const ViewDetails = (id: any) => {
    const spanLink = span === PAST ? PAST_APPOINTMENTS : UPCOMING_APPOINTMENTS;
    return (
      <Link to={`/${spanLink}/${id}`}>
        <Info fontSize="large" />{" "}
        <span className="d-block">
          {t("label.view_details")}
        </span>
      </Link>
    );
  };

  /** CONFIRMING APPOINTMENT */
  const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false);
  const ConfirmAppointment = (id: React.SetStateAction<number>, isDisable: boolean) => (
    <div
      onClick={() => {
        setActiveAppointmentId(id);
        setConfirmModalVisibility(true);
      }}
      className={isDisable ? 'Disable-ActionLayout' : ''}
      style={{color: `${tenantState.branding.theme.palette.primary.main}`}}
    >
      <Check fontSize="large" />{" "}
      <span className="d-block">
        {t("label.confirm")}
      </span>
    </div>
  );
  const onConfirmationClick = () => {
    setConfirmModalVisibility(false);
    dispatch(confirmAppointmentStatus(activeAppointmentId));
  };

  /** DECLINING APPOINTMENT */
  const [isDeclineModalVisible, setDeclineModalVisibility] = useState(false);
  const DeclineAppointment = (id: React.SetStateAction<number>, isDisable: boolean) => (
    <div
      className={`decline-button ${isDisable ? 'Disable-ActionLayout': ''}`}
      onClick={() => {
        setActiveAppointmentId(id);
        setDeclineModalVisibility(true);
      }}
    >
      <AiFillCloseCircle className="Appointment-Icon" style={{ fontSize: "3.5rem" }} />{" "}
      <span className="appointment-action-text d-block">
        {t("label.decline")}
      </span>
    </div>
  );
  // /** CHECK IN APPOINTMENT */
  const [isCheckInModalVisible, setCheckInModalVisibility] = useState(false);
  const CheckInAppointment = (id: React.SetStateAction<number>, isDisable: boolean) => (
    <div
    className={`decline-button ${isDisable ? 'Disable-ActionLayout': ''}`}
      onClick={() => {
        setActiveAppointmentId(id);
        setCheckInModalVisibility(true);
      }}
      style={{color: `${tenantState.branding.theme.palette.primary.main}`}}
    >
       <CropFree fontSize="large" />{" "}
      <span className="d-block">
        {t("label.checkedin")}
      </span>
    </div>
  );
      /** CHECK IN APPOINTMENT  */
      // const [isCheckInModalVisible, setCheckInModalVisibility] = useState(false);
      // const [selectedAppointment, setSelectedAppointment] = useState({appointmentType:'', qrCodeValue:''});
      // const CheckInAppointment = (app: React.SetStateAction<any>, isDisable: boolean) => (
      //   <div
      //   className={`decline-button ${isDisable ? 'Disable-ActionLayout': ''}`}
      //     onClick={() => {
      //       setSelectedAppointment(app);
      //       setCheckInModalVisibility(true);
      //     }}
      //     style={{color: `${tenantState.branding.theme.palette.primary.main}`}}
      //   >
      //      <CropFree fontSize="large" />{" "}
      //     <span className="d-block">
      //       {t("label.checkedin")}
      //     </span>
      //   </div>
      // );

  const onDeclineClick = () => {
    setDeclineModalVisibility(false);
    dispatch(declineAppointmentStatus(activeAppointmentId));
  };
  const onCheckInClick = () => {
    setCheckInModalVisibility(false);
    dispatch(checkedInAppointmentStatus(activeAppointmentId));
  };
  const onRescheduleClick = async (rescheduleReason) => {
    setRescheduleModalVisibility(false);
      try {
          await rescheduleAppointmentStatus(activeAppointmentId, {
            message: rescheduleReason
          });
          updateAppointmentsList();
          message.success('Request successfully sent.');
      } catch (error) {
          console.log(error);
          message.error('Submission failed, please try again.');
      }
  };

  const updateAppointmentsList = () => {
    dispatch(
      fetchAppointmentsBySpan(appointments.span, {
        length: appointments.length,
        page: appointments.currentPageNumber,
        sortDirection: appointments.sortDirection,
        sortColumn: appointments.sortColumn
      })
    )
  }

  /** TELEHEALTH */
  const StartMeeting = (appointmentId: any, isDisable: boolean) => (
    <div 
      onClick={() => onStartMeetingClick(appointmentId)} 
      style={{color: `${tenantState.branding.theme.palette.primary.main}`}}
      className={isDisable ? 'Disable-ActionLayout' : ''}
      >
      <Telegram fontSize="large" />{" "}
      <span className="d-block">
        {t("label.start_telehealth")}
      </span>
    </div>
  );

  const [isRescheduleModalVisible, setRescheduleModalVisibility] = useState(false);
  const RescheduleAppointment = (id: any, isDisable: boolean) => (
    <div onClick={() => {
      setActiveAppointmentId(id);
      setRescheduleModalVisibility(true);
    }}
    style={{color: `${tenantState.branding.theme.palette.primary.main}`}}
    className={isDisable ? 'Disable-ActionLayout' : ''}
    >
      <Clear style={{ fontSize: '3.5rem' }} />{" "}
      <span className="d-block">{t('label.reschedule')}</span>
    </div>
  );

  function onStartMeetingClick(appointmentId: any) {
    dispatch(startTelehealthMeeting(appointmentId));
  }

  return (
    <>
      {/* <QRCodeModal
          visible={isCheckInModalVisible}
          title={`${selectedAppointment?.appointmentType} ${t('label.qr_code')}`}
          onClose={() => {
            setCheckInModalVisibility(false);
          }}
          qrCode={`${PUBLIC_BASE_URL}/qrcode/${selectedAppointment?.qrCodeValue}`}
          qrCodeValue={selectedAppointment?.qrCodeValue}
        />     */}
      <ConfirmationModal
        visible={isConfirmModalVisible}
        onOk={onConfirmationClick}
        onCancel={() => setConfirmModalVisibility(false)}
      />
      <DeclineModal
        visible={isDeclineModalVisible}
        onOk={() => onDeclineClick()}
        onCancel={() => setDeclineModalVisibility(false)}
      />
      <CheckInModal
        visible={isCheckInModalVisible}
        onOk={() => onCheckInClick()}
        onCancel={() => setCheckInModalVisibility(false)}
      />

      <RescheduleModal
        visible={isRescheduleModalVisible}
        onOk={(param) => onRescheduleClick(param)}
        onCancel={() => setRescheduleModalVisibility(false)}
      />
      <Spin spinning={appointments.isListPageLoading}>
        <List
          className={'Appointments-List'}
          grid={{
            gutter: 20,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 3,
          }}
          pagination={{
            total: appointments?.recordsTotal,
            pageSize: pageSize,
            onChange: (page) => {
              dispatch(
                updateAppointmentsParams({
                  currentPageNumber: page - 1,
                  length: pageSize,
                  span: span,
                  sortColumn: 'appointmentDateTime',
                  sortDirection: span === PAST ? 'DESC' : 'ASC',
                })
              );
              fetchAppointments(page);
            },
          }}
          dataSource={appointments.list}
          renderItem={(item, i) => {
            let acceptConfirmCase = [
              AppointmentStatuses.Scheduled,
              AppointmentStatuses.Rescheduled,
              AppointmentStatuses.Unconfirmed,
            ];
            let acceptRescheduleCase = [
              AppointmentStatuses.Scheduled,
              AppointmentStatuses.Unconfirmed,
            ];

            const { id } = item;
            const appointmentConfirm = acceptConfirmCase.includes(item.status);
            const appointmentReschedule = acceptRescheduleCase.includes(
              item.status
            );

            const isConfirmed = item.status === AppointmentStatuses.Confirmed;
            const isDeclined = item.status === AppointmentStatuses.Declined;

            let ActionLayout = [ViewDetails(id)];

            if (permissions.Appointments?.includes('appointment:confirm')) {
              if (appointmentConfirm && span !== PAST) {
                ActionLayout = [ViewDetails(id), ConfirmAppointment(id, false), RescheduleAppointment(id, true), CheckInAppointment(id, true)];
              }
            }

            if (isConfirmed && span !== PAST) {
              ActionLayout = [ViewDetails(id)];
            }
            if (permissions.Appointments?.includes('appointment:telehealth')) {
              if (
                item.attendanceType === AppointmentAttendanceTypes.Telehealth &&
                span !== PAST &&
                isConfirmed
              ) {
                ActionLayout = [
                  ViewDetails(id),
                  ConfirmAppointment(id, true),
                  RescheduleAppointment(id, true),
                  StartMeeting(item, false),
                ];
              }
            }

            if (permissions.Appointments?.includes('appointment:decline')) {
              if (span !== PAST && !isConfirmed) {
                ActionLayout = [ViewDetails(id), DeclineAppointment(id, false)];
              }
            }

            // if (permissions.Appointments?.includes("appointment:reschedule")) {
            // if (span !== PAST && !isConfirmed) {
            //     ActionLayout = [...ActionLayout, RescheduleAppointment(id)];
            // }
            // }
            if (span !== PAST && isConfirmed) {
              ActionLayout = [ViewDetails(id), RescheduleAppointment(id, true), CheckInAppointment(id, false)];//
            }

            if (span !== PAST && appointmentReschedule) {
              ActionLayout = [ViewDetails(id), ConfirmAppointment(id, false), RescheduleAppointment(id, false), CheckInAppointment(id, true)];//
            }

            if (isDeclined) {
                ActionLayout = [ViewDetails(id), ConfirmAppointment(id, true), RescheduleAppointment(id, true), CheckInAppointment(id, true)];
            }

            if (item.status.includes('REFERRAL')) {
              ActionLayout = [ViewDetails(id)];
            }

            return (
              <List.Item>
                <AppointmentCard
                  ActionLayout={ActionLayout}
                  item={item}
                  span={span}
                />
              </List.Item>
            );
          }}
        />
      </Spin>
      {span === UPCOMING && appointments && appointments?.span === UPCOMING && appointments?.list && appointments.list.length > 0 && <FeedBack/>}
    </>
  );
};

export default AppointmentsModule;
