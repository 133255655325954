import axios from 'axios';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from './../constants/index';
import {store} from "../index";
import {ERROR_ACTIONS} from "../redux/types/error-types";

export function createAxios (config) {
    const instance = axios.create(config);

    instance.interceptors.request.use(config => {
        // let tokkien = getToken();
        // console.log(tokkien);
        // const token = tokkien;
        const token = Cookies.get(TOKEN_NAME);
        if(token) {
            config.headers.common['Authorization'] = `Bearer ${token}`;
        }
        return config
    })

    instance.interceptors.response.use(response => response,
        error => {
            const errorResponseObject = error.response;
            const errorJSONObject = error.toJSON();

            store.dispatch({
                type: ERROR_ACTIONS.SET_ERROR_MESSAGE,
                payload: errorResponseObject?.data?.message ? errorResponseObject?.data?.message : errorJSONObject.message
            })

            if(error.response?.status === 401) {
                // keycloak.logout();
                // setToken(null); // ??
                if (Cookies.get(TOKEN_NAME)) {
                    Cookies.remove(TOKEN_NAME);
                    // window.location.reload();
                }
                window.location.href = '/'; 
            }
            return Promise.reject(error);
        })

    return instance;
}

export function createPublicAxios (config) {
    const instance = axios.create(config);
    return instance;
}