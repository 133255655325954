import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Button, Modal, Input } from 'antd';
import { useAppSelector } from '../../../hooks/use-app-selector';

import {
    IoMdTimer
} from 'react-icons/all';

import {
    useTranslation
} from 'react-i18next';

interface RescheduleModalProps {
    visible: boolean;
    appointmentName?: string;
    onOk: (param: any) => void;
    onCancel: () => void;
};

const { TextArea } = Input;

const RescheduleModal: FunctionComponent<RescheduleModalProps> = ({ visible, appointmentName, onOk, onCancel }) => {
    const appointments = useAppSelector(state => state.appointment);
    const [isRescheduleClicked, setRescheduleClicked] = useState(false);
    const [rescheduleReason, setRescheduleReason] = useState('');
    const [reasonError, setReasonError] = useState(false);
    
    const { t } = useTranslation();

    const onRescheduleClick = () => {
        setRescheduleClicked(true);
    };

    const onCancelClick = () => {
        if (onCancel) {
            onCancel();
        }

        setReasonError(false);
        setRescheduleClicked(false);
    };

    const onOkSubmit = (param: any) => {
        if (onOk && !reasonError) {
            onOk(param);
            setReasonError(false);
            setRescheduleClicked(false);
        } else {
            setReasonError(true);
        }
    };

    return (
        <Modal
            title={appointmentName}
            visible={visible}
            footer={false}
            closable={false}
            centered
        >
            <Container fluid>
                {!isRescheduleClicked ? <Row>
                    <Col className='text-center' xs={{
                        order: 1,
                        span: 12
                    }} md={{
                        order: 1,
                        span: 12
                    }}>
                        <IoMdTimer fontSize='large' style={{ fontSize: '6rem', color: '#0288D1' }} />
                        <h3>{t('message.reschedule_appointment')}</h3>
                    </Col>
                    <Col className='text-center' xs={{
                        order: 3,
                        span: 12
                    }} md={{
                        order: 2,
                        span: 6
                    }}>
                        <Button 
                            className='mb-4' 
                            onClick={onCancelClick} 
                            block 
                            loading={appointments.isConfirmationButtonLoading}
                        >
                            {t('label.cancel')}
                        </Button>
                    </Col>
                    <Col className='text-center' xs={{
                        order: 2,
                        span: 12
                    }} md={{
                        order: 3,
                        span: 6
                    }}>
                        <Button className='mb-4' onClick={onRescheduleClick} type='primary' block  loading={appointments.isConfirmationButtonLoading}>
                            {t('label.reschedule')}
                        </Button>
                    </Col>
                </Row>
                :
                <Row>
                    <Col 
                        className='text-center' 
                        xs={{
                            order: 1,
                            span: 12
                        }} 
                        md={{
                            order: 1,
                            span: 12
                        }}
                    >
                        <IoMdTimer fontSize='large' style={{ fontSize: '6rem', color: '#0288D1' }} /><br/>
                    </Col>
                    <Col
                    className='text-left' 
                    xs={{
                        order: 2,
                        span: 12
                    }} 
                    md={{
                        order: 2,
                        span: 12
                    }}>
                        <label>Request to Reschedule</label>
                        <textarea
                            className={`ant-input ${reasonError && 'border-danger'}`}
                            onChange={(e) => {
                                setRescheduleReason(e.currentTarget.value);
                            }}
                            onBlur={() => {
                                if (!rescheduleReason) {
                                    setReasonError(true);
                                } else {
                                    setReasonError(false);
                                }
                            }}
                        />
                        <small className='text-info'>Please message the clinic with alternative dates &amp; times and staff will be in touch.</small>
                    </Col>
                    <Col 
                        className='text-center mt-4' 
                        xs={{
                            order: 3,
                            span: 12
                        }} 
                        md={{
                            order: 2,
                            span: 4
                        }}
                    >
                        <Button className='mb-4' onClick={onCancelClick} block loading={appointments.isConfirmationButtonLoading}>
                            {t('label.cancel')}
                        </Button>
                    </Col>
                    <Col 
                        className='text-center' 
                        xs={{
                            order: 2,
                            span: 12
                        }} 
                        md={{
                            order: 3,
                            span: 8
                        }}
                    >
                        <Button 
                            className='mt-4 mb-4' 
                            onClick={() => onOkSubmit(rescheduleReason)} 
                            type='primary' 
                            block 
                            loading={appointments.isConfirmationButtonLoading}
                        >
                            Request to Reschedule
                        </Button>
                    </Col>    
                </Row>}
            </Container>
        </Modal>
    )
};

export default RescheduleModal;
