import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'styles/Messages.scss';

import {Button, Card, Space} from 'antd';
import { ChevronLeft, Reply } from '@material-ui/icons';
import { Container, Row, Col } from 'react-bootstrap';

import {
    useDispatch,
    useSelector,
} from 'react-redux';

import {
    fetchMessageById,
} from 'redux/actions/messages';

import {
    setBreadcrumbsText
} from 'redux/actions/ui';

import {
    useTranslation
} from 'react-i18next';

import {
    useAppSelector
} from 'hooks/use-app-selector';

import parser from 'html-react-parser';
import { CgArrowLeftO } from 'react-icons/all';

import ReplyModal from 'components/Appointments/Modals/ReplyModal';
import CustomInboxButton from "../../components/Messages/components/CustomInboxButton";

import Interweave from "interweave";

const MessageDetails = () => {
    const messageState = useSelector(state => state.messages);
    const { subject, message, appointment, emailAddress, phoneNumber, replyingToMessage, customButtons } = messageState.data;
    const [ replyModalVisibility, setReplyModalVisibility ] = useState(false);
    const permissions = useAppSelector(state => state.permissions.permissions);

    // const testButtons = [
    //     {
    //         btnName: "Remove",
    //         btnColour: "#EC8581",
    //         btnAction:  {
    //             type: "api",
    //             url: "/",
    //             method: "POST",
    //             data: {
    //                 action: "WLISTREM"
    //             }
    //         },
    //         btnWarning: "Are you sure you want to remove yourself from the hospital waiting list?",
    //         btnResponse: "Your waiting list removal request has been sent to the hospital."
    //     }
    // ]

    const { id } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        
        dispatch(fetchMessageById(id));
        dispatch(setBreadcrumbsText(subject));

        return () => {
            dispatch(setBreadcrumbsText(''));
        }
    }, [id, subject, dispatch]);

    const returnCleanerHTMLMessage = (text) => {
        if (text) {
            const newMessage = text.replace(/<p>\s*<\/p>/ig, "<p>&nbsp;</p>");
          // ## Manually replace
          //  let breckedMessage = newMessage.replace('&#10;','<br/>');
          //breckedMessage = decodeURIComponent(breckedMessage);
            return <Interweave content={newMessage} />;
        } else {
            return null
        }
    }

    return (
        <Card className='Message-Details-Card'>
            { messageState.data && replyModalVisibility && subject &&
                <ReplyModal 
                    visible={replyModalVisibility} 
                    hide={() => setReplyModalVisibility(false) }
                    subject={`${(replyingToMessage ? 'Re: ' : '')} ${subject}`}
                    messageId={id}
                    appointmentId={appointment?.id}
                    onSuccess={() => setReplyModalVisibility(false)}
                />
            }

            <Container fluid>
                <Row>
                    <Col xs={12} lg={6}>
                        <Link to='/inbox'>
                            <Button type='link' style={{ marginTop: '5px', marginLeft: '-24px' }}>
                                <CgArrowLeftO fontSize='3.5rem' />&nbsp;&nbsp;{t('label.back_inbox')}
                            </Button>
                        </Link>
                    </Col>
                </Row><br/>
                <Row>
                    <Col xs={12} lg={12}>
                        <h1 className='Message-Subject font-weight-bolder'>
                            <small style={{ fontSize: '1rem' }} className='font-weight-bold text-muted'>SUBJECT</small><br />
                            {replyingToMessage && 'Re: '}
                            {subject}
                        </h1>
                        {/* <p>From: {emailAddress} | {phoneNumber}</p> */}
                        <hr /><br/>
                        <h4 className='Message-Text'>{returnCleanerHTMLMessage(message)}</h4>
                    </Col>
                    <Col className='text-right' xs={12} lg={12}>
                        <Space size={'small'} align={"center"}>
                            {permissions.Inbox?.includes("inbox:reply") ? <Button type='link' onClick={() => setReplyModalVisibility(true) } style={{ marginTop: '10px' }}><Reply style={{ marginTop: '-10px' }} /> {t('label.reply')}</Button> : null}
                            {customButtons?.length > 0 && customButtons.map(button => <CustomInboxButton {...button} /> )}
                        </Space>
                    </Col>
                </Row>
            </Container><br/><br/>
        </Card>
    );
};

export default MessageDetails;
