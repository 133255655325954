import {
    addUser,
} from 'api/users';

import {
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR
} from './types/users';

export const addPatient = (patient) => {
    return async dispatch => {
        try {
            dispatch({
                type: ADD_USER_REQUEST
            });

            const response = await addUser(patient);

            dispatch({
                type: ADD_USER_SUCCESS,
                payload: {
                    data: response.data,
                    status: response.status
                }
            });
        } catch (error) {
            dispatch({
                type: ADD_USER_ERROR,
                error: error
            })
        }
    }
}