import React, { useState, useEffect } from 'react';
import { getPatientTasks, updatePatientTaskStatus } from 'api/patientTasks';
import { Button, message, Card, Empty } from 'antd';
import {CompleteButton} from "../CompleteButton";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setWillTaskListFetch } from 'redux/actions/appointment';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';

const TaskList = ({setTaskId, refreshTask, setRefreshTask, appointmentId}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [patientTasks, setPatientTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const appointmentState = useSelector(state => state.appointment);
   
  useEffect(()=>{
    if((!patientTasks?.length || refreshTask) && appointmentId) {
      fetchPatientTasks(currentPage);
      setRefreshTask(0);
    }   
  },[refreshTask, appointmentId]);

  const renderPatientTasksAction = (text, record, value) => {
    let action = null;
    if(record.status === 'NEW') {
      action = 
      <CompleteButton
        type="primary"
        size='small'
        className='float-right'
        onClick={ () => {
            if (record.taskType === 'CUSTOM' || record.taskType === 'FORM' || record.taskType === 'COLLATERAL') {
              updatePatientTaskStatus(record?.id, 'COMPLETED')
              .then(res => {
                  message.success('Successfully completed task.');
                  fetchPatientTasks(currentPage);
              })
            } else if (record.taskType === 'ATTACHMENT') {
              setTaskId(record?.id);
            }
        }}
      >
        CLICK TO COMPLETE
      </CompleteButton>
    } else {
      //action = <Tag color='green' icon={<CheckCircleFilled style={{position: 'relative', top: '-2px', height: '10px'}} />}>COMPLETED</Tag>
      action = <Button className='Action-Button float-right' disabled type='primary' size='small'>COMPLETED</Button>
    }
    return action;
  }

  const fetchPatientTasks = async (page) => {
    const params = { 
      length: 5, 
      page: page,
      sortColumn: 'createdAt', 
      sortDirection: 'DESC',
      appointmentId: appointmentId
    }
    const res = await getPatientTasks(params);
    setPatientTasks(res?.data?.data);
    setTimeout(()=>{
      setIsLoading(false);
    },500);
  }

  // const columns = [
  //   {
  //     title: 'Task',
  //     dataIndex: "description",
  //     key: "description",
  //     sorter: false
  //   },
  //   {
  //     title: 'Actions',
  //     dataIndex: "id",
  //     key: "id",
  //     sorter: false,
  //     render: renderPatientTasksAction
  //   },
  // ];

  useEffect(() => {
      if (appointmentState.willTaskListFetch) {
          fetchPatientTasks(currentPage);

          return () => {
            dispatch(setWillTaskListFetch(false));
          }
      }
  }, [appointmentState.willTaskListFetch]);

  return (
    <Container className='Appointment-Info-Container' fluid>
      <Card className='Card-Gradient' bodyStyle={{ padding: 12 }}>
        {patientTasks && patientTasks.length < 1 && (
          <Row>
            <Col className='text-center mb-4 mt-4'>
              <Empty description={t('message.no_tasks')} />
            </Col>
          </Row>
        )}
        {patientTasks && patientTasks.length > 0 && patientTasks.map((task, i) => (
            <Row className='mb-2'>
              <Col xs={4} md={4} lg={4}>
                <h4 className='Attachment-Card-Title'>{task.description}</h4>
              </Col>
              <Col xs={3} md={4} lg={4}>
                <span className='font-weight-bold'>{moment(task.createdAt).format("DD MMM hh:mm A")}</span>
              </Col>
              <Col xs={5} md={4} lg={4}>
                {renderPatientTasksAction('', task, '')}
              </Col>
            </Row>          
        )
        )}
      </Card>
    </Container>
    // <Table 
    //     columns={columns}
    //     loading={isLoading}
    //     dataSource={patientTasks}
    //     pagination={{
    //       total: patientTasks?.recordsTotal,
    //       pageSize: 5,
    //       onChange: (page, pageSize) => {
    //         setCurrentPage(page - 1);
    //         fetchPatientTasks(page - 1);
    //       }
    //     }}
    // />
  );
};

export default TaskList;
