import {
    getMessagesByPatientId,
    getMessageById,
} from 'api/messages';

import {
    FETCH_MESSAGE_LIST_ERROR,
    FETCH_MESSAGE_LIST_SUCCESS,
    FETCH_MESSAGE_LIST_REQUEST,
    FETCH_MESSAGE_BY_ID_REQUEST,
    FETCH_MESSAGE_BY_ID_SUCCESS,
    FETCH_MESSAGE_BY_ID_ERROR,
    SET_MESSAGES_UNREAD_COUNT
} from 'redux/actions/types/messages';

export const fetchMessagesByUserId = (userId,
                                      currentPage = -1,
                                      length = 10,
                                      sortColumn = 'createdAt',
                                      sortDirection = 'DESC') => {
    return async dispatch => {
        try {
            dispatch({
                type: FETCH_MESSAGE_LIST_REQUEST
            });

            let response;

            if (currentPage > -1) {
                response = await getMessagesByPatientId(userId,
                    `&page=${currentPage}&length=${length}&sortColumn=${sortColumn}`
                        + `&sortDirection=${sortDirection}`);
            } else {
                response = await getMessagesByPatientId(userId);
            }

            dispatch({
                type: FETCH_MESSAGE_LIST_SUCCESS,
                payload: response.data
            });
        } catch (error) {
             dispatch({
                type: FETCH_MESSAGE_LIST_ERROR,
                error
            });
        }
    };
};

export const fetchMessageById = id => {
    return async dispatch => {
        try {
            dispatch({
                type: FETCH_MESSAGE_BY_ID_REQUEST,
            });

            const response = await getMessageById(id);

            dispatch({
                type: FETCH_MESSAGE_BY_ID_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: FETCH_MESSAGE_BY_ID_ERROR,
                error
            });
        }
    }
};

export function setMessagesUnreadCount(count) {
    return {
        type: SET_MESSAGES_UNREAD_COUNT,
        payload: count
    };
}
