import React, { useState } from 'react';
import DynamicForm from 'components/DynamicForm';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message } from 'antd';
import { RootState } from 'redux/reducers';
import { FieldComponent } from 'interfaces';
import MobileAuthenticationComponent from "modules/LoginModule/components/MobileAuthenticationComponent";
import styles from "modules/LoginModule/LoginModule.module.scss";
import { Container } from "react-bootstrap";
import { setTenantRegistration } from 'redux/actions/tenants';
import createBrowserHistory from 'history/createBrowserHistory';
import { signUpSubmit, signUpGeneral } from 'api/signup';
import ResendOTPButton from './ResendOTPButton';

interface PatientDetailsProps {
    signupUri: string
}

export default (props: PatientDetailsProps) => {
    const [loading, setLoading] = useState(false);
    const { signupUri } = props;
    const tenantState = useSelector((state:RootState) => state.tenant);
    const dispatch = useDispatch();
    const [signUpFormVisible, setSignUpFormVisible] = useState(false);
    const [currentMobileNumber, setCurrentMobileNumber] = useState('');
    const [isOtpAfterRegistrationVisible, setOtpAfterRegistrationVisibility] = useState(false);
    const [uniqueId, setUniqueId] = useState('');
    const browserHistory = createBrowserHistory({forceRefresh:true});
    // const isRegistrationPageNotStaging = tenantState?.registration?.type as REGISTRATION_TYPE !== REGISTRATION_TYPE.STAGING;

    const updateTenantRegistration = (tenantRegistration: any, id: any, property: any, value: any) => {
        if (tenantRegistration && tenantRegistration?.fields) {
            const activePropertyIndex = tenantRegistration?.fields?.findIndex(field => field.id === id);

            if (tenantRegistration?.fields[activePropertyIndex]) {
                let newProperty = {...tenantRegistration?.fields[activePropertyIndex]};
                newProperty[property] = value;
                tenantRegistration.fields[activePropertyIndex] = newProperty;

                return tenantRegistration;
            }

            return tenantRegistration;
        }
    }

    return  <>
                {/* {isRegistrationPageNotStaging 
                    && <MessagePage
                            type={MessageTypes.WARNING}
                            headingText={'Unauthorized'}
                            body={'Sorry, You are not authorized to access this page.'}
                        />}
                {!isRegistrationPageNotStaging && <DynamicForm
                    isSubmitButtonLoading={loading}
                    fields={tenantState?.registration?.fields as FieldComponent[]}
                    onSubmit={(values) => {
                        setLoading(true);
                        stagePatientUser({
                            dateOfBirth: values.dob.format('YYYY-MM-DD'),
                            firstName: values.firstName,
                            lastName: values.lastName,
                            mobileNumber: values.mobileNo
                        }).then(response => {
                            setLoading(false);
                            window.location.href = `${signupUri}/${response.data}`
                        }).catch(err => {
                            setLoading(false);
                            message.error(err.toString())
                        })
                    }}
                />} */}
                <Container className={styles["Landing-Container"]}>
                    <div className={styles["Main-Content"]}>
                        {!signUpFormVisible && <MobileAuthenticationComponent 
                                                    signUpOtpSuccess={(response) => {
                                                        const tenantRegistration = {...tenantState.registration};
                                                        const updatedTenantRegistrationFields = updateTenantRegistration(tenantRegistration, 'mobileNo', 'defaultValue', response.mobileNumber);
                                                        const addedOtpResendButton = updateTenantRegistration(
                                                            updatedTenantRegistrationFields, 
                                                            'otp', 
                                                            'additionalComponent', 
                                                            <ResendOTPButton
                                                                type='link'
                                                                mobileNumber={response.mobileNumber}
                                                                onClickCallback={(response) => {
                                                                    setUniqueId(response.uniqueId);
                                                                }}
                                                            />
                                                        );
                                                        setUniqueId(response.uniqueId);
                                                        dispatch(setTenantRegistration(addedOtpResendButton));
                                                        return setSignUpFormVisible(true)
                                                    }}
                                                />}
                        {(signUpFormVisible && !isOtpAfterRegistrationVisible) && <DynamicForm 
                            fields={tenantState?.registration?.fields as FieldComponent[]}
                            hasTermsAndConditions
                            onSubmit={(values: any) => {
                                console.log(' uniqueId',uniqueId);
                                const payload = {
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    dateOfBirth: values.dob.format('D/M/YYYY'),
                                    mobileNumber: values.mobileNo,
                                    otp: values.otp,
                                    uniqueId
                                };
                                signUpSubmit(payload).then((resp) => {
                                    browserHistory.push('/login');
                                })
                                //     .catch((error) => {
                                //     message.error(error.toString());
                                // });
                            }}
                        />}
                        {isOtpAfterRegistrationVisible && <MobileAuthenticationComponent 
                            mobileNumber={currentMobileNumber} 
                            confirmRegistration={true}
                            uniqueId={uniqueId}
                        />}
                    </div>
                </Container>
            </>
}