import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import NotificationsIcon from '@material-ui/icons/Notifications';
// import Badge from '@material-ui/core/Badge';
import { Badge, Empty, Spin } from 'antd';

import NotificationItem from 'components/Notifications/NotificationItem';

import { useSelector, useDispatch } from 'react-redux';
import {
    fetchNotificationsListByUserId,
    fetchNotificationsTallyByUserId,
    setNotificationAsRead,
} from 'redux/actions/notifications';

import {
    setNotificationsVisibility
} from 'redux/actions/ui';

import 'styles/Notifications.scss';
import useRefOutSideClick from "../../hooks";

const NotificationList = () => {
    const notificationState = useSelector(state => state.notifications);
    const uiState = useSelector(state => state.ui);
    const dispatch = useDispatch();
    const userId = 0;

    const { isNotificationsListLoading, list, tally } = notificationState;
    const { isNotificationsVisible } = uiState;

    const notificationsList = list;

    const { ref, handleClickOutside } = useRefOutSideClick( () => {
        dispatch(setNotificationsVisibility(false));
    });

    useEffect(() => {
        dispatch(fetchNotificationsTallyByUserId(userId), '&tally=true');
    }, [userId, dispatch]);

    const handleNotificationIconClick = () => {
        let currentNotificationStatus = true;

        if (uiState.isNotificationsVisible) {
            currentNotificationStatus = false;
        } else {
            dispatch(setNotificationAsRead(0));
            dispatch(fetchNotificationsListByUserId(0, 1, 10));
        }

        dispatch(setNotificationsVisibility(currentNotificationStatus));
    };

    return (
        <>
        <div style={{display: uiState.isNotificationsVisible ? 'block' : 'none'}} className='Notification-Component-Overlay' onClick={handleClickOutside}/>
        <div ref={ref} className='Notification-Component'>
            <Badge count={tally}>
                <NotificationsIcon fontSize='large' onClick={handleNotificationIconClick} />
            </Badge>
            {isNotificationsVisible && <div className='Notifications-List-Container'>
                <h4 className='Notification-Title'>Notifications</h4>
                <div className={(isNotificationsListLoading && notificationsList.length < 1 ? 'Flex-Display Notification-List' : 'Notification-List')}>
                    {isNotificationsListLoading && notificationsList.length < 1 && (
                        <Spin size="large" />
                    )}
                    {notificationsList.length < 1 && !notificationState.isNotificationsListLoading && (
                        <Empty description={'No new notification'} />
                    )}
                    {notificationsList && notificationsList.length > 0 && notificationsList.map(notification => {
                        return <NotificationItem
                                    key={notification.id}
                                    id={notification.id}
                                    notification={notification}
                                />;
                    })}
                </div>
            </div>}
        </div>
        </>
    );
};

export default NotificationList;
