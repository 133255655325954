import {API_BASE_URL} from 'api/config';
import {createAxios} from "./axios";

const signUpApi = createAxios({
    baseURL: API_BASE_URL
});


export const signUpMobileOTPValidate = payload => {
    return signUpApi.post('/otp/validate', payload);
}

export const signUpMobileOTPCreate = mobileNumber => {
    return signUpApi.post('/otp/create/mobile', mobileNumber);
}

export const signUpGeneral = payload => {
    return signUpApi.post('patient-registration/signup/general', payload);
}

export const signUpUserConfirm = payload => {
    return signUpApi.post('patient-registration/signup/general/confirm', payload);
}

export const signUpSubmit = (payload) => {
    return signUpApi.post('patient-registration/signup/general/submit', payload);
}