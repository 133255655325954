import React, { useState, useEffect } from 'react';

import {
    Col 
} from 'react-bootstrap';

import { useNode, useEditor } from '@craftjs/core';

const Column = (props) => {
    const { 
        connectors: { connect, drag },
        currentNode
    } = useNode((state) => ({
        currentNode: state
    }));
    const { query } = useEditor();
    const [colRef, setColRef] = useState(null);
    const [columns, setColumns] = useState({});
    const [willColumnTakeEffect, setColumnEffectivity] = useState(false);

    useEffect(() => {
        if (currentNode?.data?.displayName === 'Column') {
            const currentSerializedNode = query.getSerializedNodes()[currentNode.data.parent];
            console.log(currentSerializedNode.props, currentSerializedNode.props.id);
            const parentIdColumnNumber = currentSerializedNode.props.id?.replace(currentSerializedNode.props.id.substring(1, currentSerializedNode.props.id.length), '');
            setColumnEffectivity(parseInt(parentIdColumnNumber) === currentSerializedNode.nodes.length);
        }
    }, [currentNode]);

    useEffect(() => {
        if (colRef) {
            switch(colRef.parentElement.id) {
                case '1Column': 
                    setColumns({
                        xs: '12',
                        md: '12',
                        lg: '12'
                    })
                    break;
                case '2Columns':
                    setColumns({
                        xs: '12',
                        md: '6',
                        lg: '6'
                    })
                    break;
                case '3Columns':
                    setColumns({
                        xs: '12',
                        md: '4',
                        lg: '4'
                    })
                    break;

                default:
                    break;
            }
        }
    }, [colRef])

    return (
        <Col 
            className={query.getOptions().enabled && 'With-Mark'}
            ref={ref => {
                connect(drag(ref));
                setColRef(ref);
            }}
            xs={willColumnTakeEffect && columns.xs ? columns.xs : 12}
            md={willColumnTakeEffect && columns.md ? columns.md : 12}
            lg={willColumnTakeEffect && columns.lg ? columns.lg : 12}
        >
            {props.children}
        </Col>
    );
};

Column.craft = {
    displayName: 'Column'
}

export default Column;