import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Tabs,
} from "@material-ui/core";
import {
  AccountCircle,
  ExitToApp,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import { NavLink, useHistory } from "react-router-dom";
import routes from "routes";
import { keycloak } from "../../api/keycloak";
import { useTranslation } from "react-i18next";
import Tab from "@material-ui/core/Tab";
import SettingsModule from "modules/SettingsModule/index";
import { message } from "antd";
import Cookies from "js-cookie";
import { TOKEN_NAME, TOKEN_KEY } from "./../../constants/index";
import {logout as accountLogout} from 'api/login';

function BottomNavigationMenu() {
  const { t } = useTranslation();
  const history = useHistory();
  const accountMenus = [
    {
      text: "Account Settings",
      icon: <SettingsIcon />,
      onClick: () => setIsSettingsOpen(true),
    },
    {
      text: "Logout",
      icon: <ExitToApp />,
      onClick: () => {
        message.loading(t("message.signing_out"), 0);
        // Handle logouts
        if (keycloak.hasRealmRole()) {
          keycloak.logout();
        } else if (Cookies.get(TOKEN_NAME)) {
            let tokenKey = Cookies.get(TOKEN_KEY);
            accountLogout({ tokenKey: tokenKey })
                .then((data) => {
                    console.log('logout', data);
                    Cookies.remove(TOKEN_NAME);
                    Cookies.remove(TOKEN_KEY);
                    window.location.reload();
                }).catch(err => err);
        }
      },
    },
  ];
  const [toggleAccountDrawer, setToggleAccountDrawer] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const logout = (e) => {
    e.preventDefault();
    setToggleAccountDrawer(true);
  };

  return (
    <>
      <Tabs
        value={false}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        className="d-flex d-lg-none"
        textColor="primary"
        style={{
          position: "fixed",
          bottom: "0",
          height: "unset",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        {routes() &&
          routes().length > 0 &&
          routes().map((route) => (
            <Tab
              key={route.path}
              style={{ alignSelf: "flex-start", maxWidth: "8em" }}
              className={route.className}
              icon={route.icon}
              label={<small style={{}}>{route.title}</small>}
              component={NavLink}
              to={route.path}
            />
          ))}
        <Tab
          className='BottomNavigationTab'
          style={{ alignSelf: "flex-start", maxWidth: "8em" }}
          label={<small style={{}}>{t("label.account")}</small>}
          icon={<AccountCircle fontSize="large" />}
          onClick={logout}
        />
      </Tabs>
      <SwipeableDrawer
        anchor={"bottom"}
        open={toggleAccountDrawer}
        onClose={() => setToggleAccountDrawer(false)}
        onOpen={() => setToggleAccountDrawer(true)}
      >
        <List>
          {accountMenus.map((menu, index) => (
            <ListItem button key={index} onClick={menu.onClick}>
              <ListItemIcon>{menu.icon}</ListItemIcon>
              <ListItemText primary={menu.text} />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      <SettingsModule
        isOpen={isSettingsOpen}
        handleClose={() => setIsSettingsOpen(false)}
      />
    </>
  );
}

export default BottomNavigationMenu;
