import { AppointmentState } from './../types/appointment-types';
import { 
    FETCH_APPOINTMENTS_LIST_REQUEST,
    FETCH_APPOINTMENTS_LIST_SUCCESS,
    FETCH_APPOINTMENTS_LIST_ERROR,
    FETCH_APPOINTMENT_ERROR, 
    FETCH_APPOINTMENT_REQUEST, 
    FETCH_APPOINTMENT_SUCCESS,
    CONFIRM_APPOINTMENT_ERROR,
    CONFIRM_APPOINTMENT_SUCCESS,
    CONFIRM_APPOINTMENT_REQUEST,
    UPLOAD_ATTACHMENT_REQUEST,
    UPLOAD_ATTACHMENT_SUCCESS,
    UPLOAD_ATTACHMENT_ERROR,
    DELETE_ATTACHMENT_REQUEST,
    DELETE_ATTACHMENT_SUCCESS,
    DELETE_ATTACHMENT_ERROR,
    SET_WILL_TASK_LIST_FETCH,
    UPDATE_APPOINTMENTS_PARAMS
} from 'redux/actions/types/appointment';

const INITIAL_STATE: AppointmentState = {
    data: {},
    list: [],
    attachment: {},
    recordsTotal: 0,
    isLoading: false,
    isSaveAttachmentLoading: false,
    isListPageLoading: false,
    isConfirmationButtonLoading: false,
    isDeleteAttachmentLoading: false,
    error: "",
    willTaskListFetch: false,
    currentPageNumber: 0,
    length: 6,
    span: "UPCOMING",
    sortColumn: "appointmentDateTime",
    sortDirection: "ASC"
}

const appointmentReducer = (state = INITIAL_STATE, action: any): AppointmentState => {
    const {type, payload, error} = action;
    switch (type) {
        case FETCH_APPOINTMENTS_LIST_REQUEST: {
            return {
                ...state,
                isListPageLoading: true,
            }
        }

        case FETCH_APPOINTMENTS_LIST_SUCCESS: {
            return {
                ...state,
                recordsTotal: payload.recordsTotal,
                list: [...payload.data],
                isListPageLoading: false,
            }
        }

        case FETCH_APPOINTMENTS_LIST_ERROR: {
            return {
                ...state,
                isListPageLoading: false,
                error,
            }
        }

        case FETCH_APPOINTMENT_REQUEST: {
            return { 
                ...state,
                isLoading: true
            }
        }

        case FETCH_APPOINTMENT_SUCCESS: {
            return { 
                ...state,
                isLoading: false,
                data: {...payload}
            }
        }

        case FETCH_APPOINTMENT_ERROR: {
            return {
                ...state,
                isLoading: false,
                error
            }
        }

        case CONFIRM_APPOINTMENT_REQUEST: {
            return {
                ...state,
                isConfirmationButtonLoading: true,
            }
        }

        case CONFIRM_APPOINTMENT_SUCCESS: {
            let newAppointmentsList = [...state.list];
            
            const currentAppointmentIdx = newAppointmentsList.findIndex(appointment => appointment.id === payload.id);

            if (!payload.locationName) {
                payload.locationName = payload.location.name;
            }

            newAppointmentsList[currentAppointmentIdx] = payload;

            return {
                ...state,
                list: [...newAppointmentsList],
                isConfirmationButtonLoading: false,
            }
        }

        case CONFIRM_APPOINTMENT_ERROR: {
            return {
                ...state,
                isConfirmationButtonLoading: false,
            }
        }

        case UPLOAD_ATTACHMENT_REQUEST: {
            return {
                ...state,
                isSaveAttachmentLoading: true
            }
        }

        case UPLOAD_ATTACHMENT_SUCCESS: {
            return {
                ...state,
                isSaveAttachmentLoading: false,
                attachment: payload
            }
        }

        case UPLOAD_ATTACHMENT_ERROR: {
            return {
                ...state,
                error
            }
        }

        case DELETE_ATTACHMENT_REQUEST: {
            return {
                ...state,
                isDeleteAttachmentLoading: true
            }
        }

        case DELETE_ATTACHMENT_SUCCESS: {
            return {
                ...state,
                isDeleteAttachmentLoading: false,
                attachment: payload
            }
        }

        case DELETE_ATTACHMENT_ERROR: {
            return {
                ...state,
                isDeleteAttachmentLoading: false,
                error
            }
        }

        case SET_WILL_TASK_LIST_FETCH: {
            return {
                ...state,
                willTaskListFetch: payload
            }
        }

        case UPDATE_APPOINTMENTS_PARAMS: {
            return {
                ...state,      
                currentPageNumber: payload.currentPageNumber,
                length: payload.length,
                span: payload.span,
                sortColumn: payload.sortColumn,
                sortDirection: payload.sortDirection
            }
        }

        default:
            return state
    }
}

export default appointmentReducer;
