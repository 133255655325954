import React, { useState, useRef, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Breadcrumbs from '../components/Breadcrumbs';
import { Launcher } from 'react-chat-window';
import { Button , message } from 'antd';

import Footer from 'components/Layout/Footer';

import {
    setTourVisibility
} from 'redux/actions/ui';

import { connect } from 'react-redux';

import Tour from 'reactour';
import { useAppSelector } from './../hooks/use-app-selector';

import SockJsClient  from 'react-stomp';
import { BASE_URL } from "api/config";

import {
    setNotificationsUnReadCount
} from 'redux/actions/notifications';
import moment from 'moment';
import GitInfo from 'react-git-info/macro';
import IdleTimer from "react-idle-timer";
import { TOKEN_NAME , TOKEN_KEY } from './../constants/index';
import Cookies from 'js-cookie';
import { logout } from 'api/login';

function BasePage(props) {
    const { pageTitle, pageName, children, isTourVisible, setTourVisibility } = props;
    const [messageList, setMessageList] = useState([]);
    const [buildVersion, setBuildVersion] = useState(null);
    
    const permissions = useAppSelector(state => state.permissions.permissions)
    // set 900 as per old implementation
    const logoutTime = 900;
    const tenantState = useSelector((state) => state.tenant);
    const dispatch = useDispatch()
    //let sockJSRef = useRef(null);

    //useEffect(() => {
    //    if (sockJSRef) {
    //        sockJSRef.connect();
    //    }
    //}, [sockJSRef])

    const onIdle = e => {
        if (Cookies.get(TOKEN_NAME)) {
            let tokenKey = Cookies.get(TOKEN_KEY);
            logout({ tokenKey: tokenKey })
                .then((data) => {
                    Cookies.remove(TOKEN_NAME);
                    Cookies.remove(TOKEN_KEY);
                    window.location.reload();
                }).catch(err => err);
        } 
    };

    const _onMessageWasSent = (message) => {
        setMessageList([...messageList, message, {
          author: 'them',
          type: 'text',
          data: { text: 'Conversation scripts have not been setup yet.' }
        }]);
    }

    useEffect(() => {
        const gitInfo = GitInfo();
        setBuildVersion(`${gitInfo.commit.shortHash}`);
    }, []);

    const steps = [
        {
            selector: '.upcoming-appointments-link',
            content: 'Click this link to see the list of upcoming appointments',
        },
        {
            selector: '.past-appointments-link',
            content: 'Click this link to see the list of past appointments',
        },
        {
            selector: '.inbox-link',
            content: 'Click this link to see the list of messages',
        },
        {
            selector: '.heartrate-link',
            content: 'Click this link to see the output from your heart monitor',
        },
        {
            selector: '.sc-launcher',
            content: 'If you need some help just click this balloon.'
        }
    ];

    //const sockets = [
    //    {
    //        url: `${BASE_URL}/ws/notifications-unread-count`,
    //        topics: ['/topic/notifications'],
    //        callback: (response) => {
    //            dispatch(setNotificationsUnReadCount(response));
    //        }
    //    }
    //]

    return (
        <div id='basePage'>
            {/*{
                sockets.map((socket) => <>
                    <SockJsClient
                        url={socket.url}
                        topics={socket.topics}
                        onMessage={socket.callback}
                        onConnect={() => console.log('connected')}
                        onConnectFailure={() => console.log('not connected')}
                        ref={(client) => sockJSRef = client}
                    />
                </>)
            }*/}
            <h4 className='text-center font-weight-bold' style={{color: `${tenantState.branding.theme.palette.primary.main}`}}>{tenantState.branding.page.appTitle}</h4>
            <Breadcrumbs />
            <h1 className='fivef-pageTitle'>{(pageTitle ? pageTitle : pageName)}</h1>
            <IdleTimer
                element={document}
                onIdle={onIdle}
                timeout={1000 * logoutTime}
            />
            {children}
            <Footer />
            {permissions.Chatbot ? <Launcher
                agentProfile={{
                    teamName: 'Sydney LHD',
                    imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
                }}
                onMessageWasSent={_onMessageWasSent.bind(this)}
                messageList={messageList}
                showEmoji
            /> : null}
            <Tour 
                steps={steps}
                isOpen={isTourVisible}
                lastStepNextButton={<Button type='primary'>Done!</Button>}
                disableInteraction={true}
                onRequestClose={() => {
                    setTourVisibility(false);
                    localStorage.setItem('onboarded', true);
                }}
            />
            <div className='text-right'>
                <small>Build version: <strong>{buildVersion}</strong></small>
            </div>
        </div>    
    );
}

export default connect(
    state => {
        return {
            isTourVisible: state.ui.isTourVisible,
            pageTitle: state.ui.pageTitle
        }
    },
    dispatch => {
        return {
            setTourVisibility: isTourVisible => dispatch(setTourVisibility(isTourVisible))
        }
    }
)(BasePage);