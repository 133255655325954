import React, { useEffect, useState, useRef } from 'react';
import {HiOutlineUser, HiPhone} from 'react-icons/hi';
import { CallClient, LocalVideoStream, VideoStreamRenderer } from '@azure/communication-calling';
import { CommunicationIdentityClient } from '@azure/communication-identity';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { v4 as uuidv4 } from 'uuid';
import { VideoContainer, RemoteVideoContainer, LocalVideoContainer } from './index.styles';
import { Button,message } from 'antd';
import {ImPhoneHangUp} from "react-icons/im";

interface TeamsChatProps {
    visible: boolean,
    activeCallee: string,
    connectionStringEndpointUrl: string,
    accessKey: string,
    afterTeamsChatInitialised?: Function,
    calendarUserId: string,
    callHangupTimerEnabled: boolean,
    fetchOnlineMeetingRequest: Function,
    createOnlineMeetingRequest: Function,
    deleteOnlineMeetingRequest: Function,
    updateOnlineMeetingRequest: Function,
    onTeamsChatInitialiseFailure: Function,
};

const TeamsChat: React.ForwardRefRenderFunction<unknown, TeamsChatProps> = (props: TeamsChatProps) => {
    const {
        visible,
        activeCallee,
        connectionStringEndpointUrl,
        accessKey,
        afterTeamsChatInitialised,
        fetchOnlineMeetingRequest,
        createOnlineMeetingRequest,
        deleteOnlineMeetingRequest,
        updateOnlineMeetingRequest,
        calendarUserId,
        onTeamsChatInitialiseFailure,
        callHangupTimerEnabled
    } = props;

    let any: any;

    let currentInvokedCall = useRef(any);
    let localVideoRef = useRef(any);
    let remoteVideoRef = useRef(any);

    const [callClient, setCallClient] = useState(any);
    const [teamsChatVisibility, setTeamsChatVisibility] = useState(visible);
    const [rendererLocal, setRendererLocal] = useState(any);
    const [rendererRemote, setRendererRemote] = useState(any);
    const [callAgent, setCallAgent] = useState(any);
    const [callStateText, setCallStateText] = useState('');
    const [isMeetingInitializing, setMeetingInitializer] = useState(true);
    const [token, setToken] = useState('');
    const [onlineMeetingId, setOnlineMeetingId] = useState('');

    useEffect(() => {
        onTeamsChatInitialise();
    }, []);

    useEffect(() => {
        if (teamsChatVisibility) {
            onTeamsChatStatusActive();
        }
    }, [teamsChatVisibility]);

    useEffect(() => {
        setTeamsChatVisibility(visible);
    }, [visible]);

    useEffect(() => {
        if (callAgent) {
            callAgent.on('callsUpdated', (e:any, listener:any) => {
                console.log('callsUpdated', e, listener);
                e.removed.forEach((removedCall:any) => {
                    rendererLocal.dispose();
                    rendererRemote.dispose();
                });
            });
        }
    }, [callAgent, rendererLocal, rendererRemote]);

    const onHangUp = async (currentCall: any) => {
        try {
            rendererLocal.dispose();
        } catch (err) {}

        try {
            rendererRemote.dispose();
        } catch (err) {}

        if (currentCall) {
            await currentCall.hangUp();
        }

    };

    const initiateCallHangupTimerIfInLobby = (currentCall: any, meetingUuid: string, meetingId: string) => {
        if (currentCall.state === 'Connected') {
            clearTimeout(currentInvokedCall.current);
        } else if (currentCall.state === 'InLobby') {
            currentInvokedCall.current = setTimeout(async () => {
                if (currentInvokedCall.current) {
                    clearTimeout(currentInvokedCall.current);
                    if (currentCall) {
                        await onHangUp(currentCall);
                        await initiateRemovalOfOnlineMeeting(meetingUuid, meetingId, 'UNANSWERED');
                    }
                }
            }, 45000);
        }
    };

    const onTeamsChatInitialise = async () => {
        let callClient = null;

        if (!callClient) {
            callClient = new CallClient();
            const connectionString = `${connectionStringEndpointUrl}/;accesskey=${accessKey}`;
            const identityClient = new CommunicationIdentityClient(connectionString);
            let identityResponse = await identityClient.createUser();
            console.log(`Created and identity with ID: ${identityResponse.communicationUserId}`);

            let tokenResponse = await identityClient.getToken(identityResponse, ['voip']);
            const { token, expiresOn } = tokenResponse;
            console.info(`\nIssued an access token with 'voip' scope that expires at ${expiresOn}:`);
            console.info(token);

            setToken(token);
            setCallClient(callClient);

            if (afterTeamsChatInitialised) {
                afterTeamsChatInitialised();
            }
        }
    };

    const localVideoView = async (localVideoStream: any) => {
        const rendererLocal = new VideoStreamRenderer(localVideoStream);
        setRendererLocal(rendererLocal);
        const view = await rendererLocal.createView();
        try {
            localVideoRef.current.removeChild(view?.target);
        } catch {
            if (localVideoRef.current) {
                const children = localVideoRef.current.children;
                for (var i = 0; i < children.length; i++) {
                    localVideoRef.current.removeChild(children[i]);
                }

                localVideoRef.current.appendChild(view?.target);
            }
        }
    }

    const initiateCreateOnlineMeeting = async (
        currentCallee: string,
        uuid: string
    ) => {
        let responseStatus = ''

        try {
            const response = await createOnlineMeetingRequest({
                calendarUserId,
                subject: `${currentCallee} is calling`,
                content: `${currentCallee} is calling`,
                id: uuid
            });

            responseStatus = response.data.status;
        } catch (err) {
            message.error('Error occurred while trying to generate a call meeting link.');
            responseStatus = '';

            if (onTeamsChatInitialiseFailure) {
                onTeamsChatInitialiseFailure();
            }
        }

        return responseStatus;
    };

    const initiateRemovalOfOnlineMeeting = async (meetingUuid: string, meetingId: string, callStatus: string) => {
        try {
            await updateOnlineMeetingRequest({
                status: callStatus,
                id: meetingUuid
            });
        } catch (err) {
            message.error('Error occurred while trying to delete the current online meeting.');
        }

        try {
            await deleteOnlineMeetingRequest({
                calendarUserId,
                id: meetingId
            });
        } catch (err) {
            message.error('Error occurred while trying to delete the current online meeting.');
        }
    };

    const subscribeToRemoteParticipantInCall = (callInstance: any) => {
        callInstance.on('remoteParticipantsUpdated', (e:any) => {
            e.added.forEach((p:any) => {
                subscribeToParticipantVideoStreams(p, callInstance);
            })
        });
        callInstance.remoteParticipants.forEach((p:any) => {
            subscribeToParticipantVideoStreams(p, callInstance);
        })
    };

    const subscribeToParticipantVideoStreams = (remoteParticipant:any, callInstance:any) => {
        remoteParticipant.on('videoStreamsUpdated', (e:any) => {
            e.added.forEach((v:any) => {
                handleVideoStream(v, callInstance);
            })
        });
        remoteParticipant.videoStreams.forEach((v:any) => {
            handleVideoStream(v, callInstance);
        });
    };

    const handleVideoStream = (remoteVideoStream:any, callInstance:any) => {
        remoteVideoStream.on('isAvailableChanged', async () => {
            if (remoteVideoStream.isAvailable) {
                remoteVideoView(remoteVideoStream);
            } else {
                try {
                    const children = remoteVideoRef.current.children;
                    for (var i = 0; i < children.length; i++) {
                        console.log('removing a child');
                        remoteVideoRef.current.removeChild(children[i]);
                    }
                    rendererRemote.dispose();
                } catch {
                }
            }
        });
        if (remoteVideoStream.isAvailable) {
            remoteVideoView(remoteVideoStream);
        }
    };

    const remoteVideoView = async (remoteVideoStream:any) => {
        let newRendererRemote = new VideoStreamRenderer(remoteVideoStream);
        setRendererRemote(newRendererRemote);
        const view = await newRendererRemote.createView();
        const children = remoteVideoRef.current.children;
        for (var i = 0; i < children.length; i++) {
            console.log('removing a child');
            remoteVideoRef.current.removeChild(children[i]);
        }
        remoteVideoRef.current.appendChild(view.target);
    };

    const killCall = async () => {
        setCallStateText('Ending meeting');
        try {
            await deleteOnlineMeetingRequest({
                calendarUserId,
                id: onlineMeetingId
            });
        } catch (err) {
            console.log(err)
            // message.error('Error occurred while trying to delete the current online meeting.');
        }
        window.location.reload();
    }

    const onTeamsChatStatusActive = async () => {
        let tokenCredential = new AzureCommunicationTokenCredential(token);
        let newCallAgent = await callClient.createCallAgent(tokenCredential, { displayName: activeCallee });
        let deviceManager = await callClient.getDeviceManager();

        await deviceManager.askDevicePermission({ video: true });
        await deviceManager.askDevicePermission({ audio: true });

        setCallAgent(newCallAgent);

        if (deviceManager) {
            const videoDevices = await deviceManager.getCameras();
            const videoDeviceInfo = videoDevices[0];
            const localVideoStream = new LocalVideoStream(videoDeviceInfo);
            const placeCallOptions = { videoOptions: { localVideoStreams: [localVideoStream] } };
            localVideoView(localVideoStream);

            const meetingUuid = uuidv4();

            const onlineMeetingCreationStatus = await initiateCreateOnlineMeeting(activeCallee, meetingUuid);

            if (onlineMeetingCreationStatus === 'SUCCEEDED') {
                let meetingLink = '';

                try {
                    const onlineMeetingResponse = await fetchOnlineMeetingRequest(
                        [
                            {
                                "member" : "$.id",
                                "type" : "eq",
                                "string" : true,
                                "value" : meetingUuid
                            }
                        ]
                    );

                    meetingLink = onlineMeetingResponse.data[0]?.joinUrl || '';

                    const onlineMeetingId = onlineMeetingResponse.data[0]?.meetingId;
                    setOnlineMeetingId(onlineMeetingId);

                    if (meetingLink !== '') {
                        let newCall = newCallAgent.join({ meetingLink }, placeCallOptions);

                        newCall.on('stateChanged', async () => {
                            console.log(newCall.state);
                            if (newCall.state === 'Disconnected') {
                                setCallStateText('Ending meeting...');
                                await initiateRemovalOfOnlineMeeting(meetingUuid, onlineMeetingId, 'ANSWERED');
                                window.location.reload();
                            } else if (newCall.state === 'Connected' || newCall.state === 'InLobby') {
                                setMeetingInitializer(false);
                                if (callHangupTimerEnabled) {
                                    initiateCallHangupTimerIfInLobby(newCall, meetingUuid, onlineMeetingId);
                                }
                                setCallStateText(newCall.state);
                            } else {
                                setCallStateText(newCall.state);
                            }
                        });
                        subscribeToRemoteParticipantInCall(newCall);
                    }
                } catch (err) {
                    message.error('Error occurred while trying to get meeting details');
                }
            }
        }
    };

    return  <VideoContainer
        style={{
            display: `${visible ? 'flex' : 'none'}`
        }}
    >
        <p><span>{callStateText}</span></p>
        <RemoteVideoContainer>
            <div id='remoteVideo' ref={remoteVideoRef}>
                <HiOutlineUser style={{ color: '#fff' }} fontSize={'10rem'} />
                {isMeetingInitializing ? <><br/><h2 style={{ color: '#fff' }}>Initializing Meeting</h2></> : null}
                <div style={{flexBasis:'100%',alignContent:'flex-start', paddingTop:'1rem'}}>

                    {onlineMeetingId ?<Button   size="large" style={{width:'6rem', height:'6rem'}}  shape="circle" onClick={() => killCall()} icon={<ImPhoneHangUp style={{color: '#D8320E',fontSize:'4rem'}}  />}/> : null}
                </div>
            </div>
        </RemoteVideoContainer>
        <LocalVideoContainer>
            <div id='localVideo' ref={localVideoRef}  ></div>
            <p>{activeCallee}</p>
        </LocalVideoContainer>
    </VideoContainer>
};

export default TeamsChat;
