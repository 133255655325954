import React, { useState, useRef} from "react";
import {Typography, Modal, Row, Col , Spin , Input, message } from "antd";
import { patientDetailsChanged } from 'api/patients'

const { Title } = Typography;
const { TextArea } = Input;

const UserDetailsChangedModal = ({visible, setVisibility}) => {
  const [loading,setLoading] = useState(false);
  const [textareatext,setTextAreaText] = useState('');

  const addTask = async () => {
    if(textareatext) {
      setLoading(true);
      let payload = {
        description: textareatext
      }
      await patientDetailsChanged(payload).then((resp) => {
        setLoading(false);
        setTextAreaText('');
        setVisibility(false);
        message.success('Change Details Request Sent Successfully');
      }).catch((err) => {
        message.error('Failed To Create Task');
        setVisibility(false);
      })
    } else {
      message.error('Please Write Some Message');
    }
  }

  return (
    <Spin spinning={loading}>
      <Modal
        title={<Title level={5} className="mb-0"
        style={{display: "flex", alignItems: "center"}}>Update my info</Title>}
        visible={visible}
        onOk={()=>{addTask();}}
        onCancel={() => { setVisibility(false); setTextAreaText('');}}
        okText={"Submit"}
        cancelText={"Cancel"}
      >
        <Row>
            <Col>To Update Your Details, Please Send a Message to the Clinic With the Changes You Need.</Col>
        </Row>
        <Row>
            <Col><label>Message :</label></Col>
        </Row>
        <Row>
            <Col span={24}><TextArea rows={4} placeholder="Enter Your Message Here" style={{width:'100%'}} onChange={(e) => {setTextAreaText(e.target.value)}} value={textareatext} required /></Col>
        </Row>
      </Modal>
    </Spin>
  );
};

export default UserDetailsChangedModal;
