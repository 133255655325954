import React, { FunctionComponent, useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from 'yup';
import { Row, Col } from "react-bootstrap";
import InputField from "components/Controls/InputField";
import { Button, message, Modal } from 'antd';
import { useTranslation } from "react-i18next";
import OTPComponent from './../OTPComponent/index';
import Interweave from 'interweave';
import { useDispatch, useSelector } from "react-redux";
import { getPublicSetting } from 'api/public/settings';
import {login} from "../../../../api/login";
import { signUpMobileOTPValidate, signUpMobileOTPCreate, signUpGeneral, signUpUserConfirm } from '../../../../api/signup';
import { useLocation } from 'react-router-dom';

interface MobileAuthenticationComponentProps {
    signUpOtpSuccess?(callBackResponse?: any): any;
    confirmRegistration?: boolean;
    mobileNumber?: string;
    uniqueId?: string
}

interface OtpResponseProps {
    code: string;
    uniqueId: string;
}

const MobileAuthenticationComponent: FunctionComponent<MobileAuthenticationComponentProps> = (
    props
) => {
    const location = useLocation();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showOTPComponent, setShowOTPComponent] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [currentUrl, setCurrentUrl] = useState('');
    const [otpResp, setOtpResp] = useState<OtpResponseProps>();
    const sysPropertiesState = useSelector((state: any) => state.sysProperties);
    const [isFaqModalVisible, setFaqModalVisibility] = useState(false);
    const [helper, setHelper] = useState();
    const dispatch = useDispatch();

    const HelperFunction = async () =>{
          const response = await getPublicSetting({
            category: 'TENANT',
            dataType: 'HTML',
            name: 'PATIENT_TENANT_FAQS_SCREEN',
            defaultValue: ''
          })

          if (response?.data?.value) {
            setHelper(response.data.value);
            // dispatch(setHelpScreenData(response.data.value));
          }
    }

  useEffect(() => {
    // fetchHideSettings();
      if (props?.confirmRegistration) {
          setShowOTPComponent(true);
          setMobileNumber(props?.mobileNumber);
      }
        HelperFunction();

  }, []);

    useEffect(() => {
        setCurrentUrl(location?.pathname.replace('/',''));
    }, [location])

    const otpCheck = async (otppass: string, currentUrl: any) => {
        // console.log(otppass);

        let payload = {
            code: otppass,
            uniqueId: otpResp?.uniqueId
        }
        if (currentUrl === 'signup') {
            await signUpUserConfirm(payload).then(res => {
                if (res?.data) {
                    props?.signUpOtpSuccess();
                } else {
                    message.error('Incorrect OTP Entered.');
                }
            })
        } else {
            await signUpMobileOTPValidate(payload).then((res) => {
                if(res?.data) {
                    props?.signUpOtpSuccess();
                } else {
                    message.error("Incorrect OTP Entered.");
                }
            })
        }
        // if(otppass === otpResp?.code) {
        //     props?.signUpOtpSuccess();

        // } else {

        // }
    }

    const getButtonTitle = (currentUrl) => {
        if (currentUrl === 'signup') {
            return 'Sign Up'
        } else if (currentUrl === 'signup' && props?.confirmRegistration === true) {
            return 'Submit'
        } else {
            return 'Log In'
        }
    }

    return (<>
        <Modal
            title='Help'
            visible={isFaqModalVisible}
            onCancel={() => setFaqModalVisibility(false)}
            destroyOnClose={true}
            footer={null}
            centered
            bodyStyle={{ height: '90vh', overflowY: 'auto' }}
        >
             <Interweave content={sysPropertiesState?.helpScreenData ? sysPropertiesState?.helpScreenData  : helper} />
        </Modal>
        <Formik
            initialValues={{
                mobileNumber: "",
            }}
            validationSchema={Yup.object({
                mobileNumber: Yup.string().required(t("message.mobile_no_required")),
            })}
            onSubmit={(values) => {
                if (currentUrl === 'signup') {
                    const payload = {
                        mobileNumber: values.mobileNumber,
                    }
                    setMobileNumber(values.mobileNumber)
                    signUpGeneral(payload)
                        .then((res) => {
                            debugger;
                            // setOtpResp(res?.data);
                            // setShowOTPComponent(true);
                            props?.signUpOtpSuccess({
                                mobileNumber: values.mobileNumber,
                                uniqueId: res?.data?.data?.uniqueId
                            });
                        }).catch((err) => {
                            console.log(err)
                        })
                    // setShowOTPComponent(true);
                } else {
                    const payload = {
                        contactNumber: values.mobileNumber,
                    };
                    setMobileNumber(values.mobileNumber)
                    message.destroy();
                    login(payload)
                        .then(e => {
                            setShowOTPComponent(true);
                        })
                        .catch(e => {
                            if (e.response && e.response.status === 500) {
                                message.error("An error has occurred. Please try again later.")
                            }
                            // setIsLoading(false)
                        })
                }
                // completeAddPatientUser(payload, uniqueId)
                //   .then((response) => {
                //     message.success("Successful");

                //     setTimeout(() => {
                //       keycloak.login({
                //         redirectUri: window.location.href.replace(signupUri, ""),
                //       });
                //     }, 2000);
                //   })
                //   .catch((err) => {
                //     const response = err.response;
                //     if (response && response.data && response.status === 409) {
                //       message.error("Username already exist");
                //     } else {
                //       console.log(response);
                //       message.error("Something went wrong");
                //     }
                //   })
                //   .finally(() => setIsLoading(false));
            }}
        >
            {showOTPComponent ? 
            <OTPComponent 
                mobileNumber={mobileNumber} 
                uniqueId={props?.uniqueId}
                confirmRegistration={props.confirmRegistration}
                signUpOtpSuccess={otpCheck}
            /> : <Form>
                <Row className="justify-content-center">
                    <Col xs={12} sm={4}>
                        <p className="mt-5">{currentUrl === 'signup' ? '' : 'Log In'}</p>
                        <Field
                            id="mobileNumber"
                            name="mobileNumber"
                            label=""
                            placeholder={t("placeholder.enter_mobile_no")}
                            component={InputField}
                            autocomplete="off"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center my-5">
                    <Col xs={8} sm={3}>
                        <Button
                            htmlType="submit"
                            loading={isLoading}
                            type="primary"
                            block
                        >
                            {getButtonTitle(currentUrl)}
                        </Button>
                    </Col>
                </Row>
                {/* <div className="Content-Footer">
          <Row>
            <Col>
              <Button
                htmlType="submit"
                loading={isLoading}
                type="primary"
                block
              >
                Sign Up
              </Button>
            </Col>
          </Row>
          <div className="Sub-Footer">
            <Row>
              <Col>
                Already have an account?{" "}
                <Link to="#" onClick={() => (window.location.href = "/")}>
                  Login here
                </Link>
              </Col>
            </Row>
          </div>
        </div> */}
            </Form>}
        </Formik>
        <Button type={'link'} onClick={() => setFaqModalVisibility(true)}>
            <span style={{ color: "red" }}>Help</span>
        </Button>
    </>
    );
};

export default MobileAuthenticationComponent