import React, {FunctionComponent,  useCallback} from 'react';
import {Modal, message} from "antd";
import Interweave from 'interweave';
import {createAxios} from "../../../api/axios";

interface CustomInboxButtonProps {
    btnName: string;
    btnColour: string;
    btnLabelColour: string;
    btnAction: any;
    btnWarning: string;
    btnResponse: string;
}

const axios = createAxios();

const CustomInboxButton: FunctionComponent<CustomInboxButtonProps> = ({btnColour, btnName, btnAction, btnWarning, btnResponse, btnLabelColour}) => {

    const buttonStyles = {
        backgroundColor: btnColour,
        border: "none",
        color: btnLabelColour,
        padding: "0.5rem"
    };

    const handleButtonClick = useCallback(() => {
        if (btnWarning) {
            Modal.warning({
                title: 'Confirmation',
                content: <Interweave content={btnWarning} />,
                onOk: async () => await handleAction(),
                closable: true,
                maskClosable: true
            })
        }
    }, [])

    const handleAction = useCallback(async () => {
      try {
        await processAction(btnAction)
        message.success(btnResponse)
      } catch (e) {
        console.log(e);
      }
    }, [])

    const processAction = useCallback(async ({type, method, url, data}) => {
        switch(type) {
            case "API":
                await axios({
                    method,
                    url,
                    data
                })
                break;
        }
    }, [])

    return <button style={buttonStyles} onClick={handleButtonClick}>{btnName}</button>
}

export default CustomInboxButton;
