import {createPublicAxios} from './axios';
import { QUERY_API_URL } from './config';
import { 
    SM__Unmanned,
    SM__UnmannedGetOnlineMeeting,
    SM__UnmannedUpdateOnlineMeeting,
    TYPE__QueryOnlineMeeting,
    TYPE__CreateOnlineMeeting, 
    TYPE__DeleteOnlineMeeting,
    TYPE__UpdateOnlineMeeting,
    SM__UnmannedGetSettings,
} from '../stateMachines/unmanned';

const unmannedApi = createPublicAxios({
    baseURL: QUERY_API_URL + '/unmanned-api/v1/',
    headers: {
        'x-api-key': '9227655b-ac0b-43c8-a901-b880fb6913ce'
    }
});

export const createOnlineMeeting = (query: TYPE__CreateOnlineMeeting) => {
    const type = 'createOnlineMeeting';

    const payload: SM__Unmanned = {
        workflow: type,
        query: query
    };

    return unmannedApi.post('instantiateSyncWorkflow', payload);
};

export const getOnlineMeeting = (criteria: Array<TYPE__QueryOnlineMeeting>) => {
    const type = 'online_meeting';

    const payload: SM__UnmannedGetOnlineMeeting = {
        type,
        criteria
    };

    return unmannedApi.post('query', payload);
};

export const deleteOnlineMeeting = (query: TYPE__DeleteOnlineMeeting) => {
    const type = 'deleteOnlineMeeting';
    
    const payload: SM__Unmanned = {
        workflow: type,
        query
    };

    return unmannedApi.post('instantiateSyncWorkflow', payload);
};

export const updateOnlineMeetingDetails = (query: TYPE__UpdateOnlineMeeting) => {
    const type = 'updateOnlineMeetingDetails';

    const payload: SM__UnmannedUpdateOnlineMeeting = {
        workflow: type,
        query
    };

    return unmannedApi.post('instantiateSyncWorkflow', payload);  
};

export const getSettings = () => {
    const type = 'settings';

    const payload: SM__UnmannedGetSettings = {
        type: type,
        criteria: []
    };

    return unmannedApi.post('query', payload);
};