import styled from "styled-components";
import {Button} from "antd";

export const CompleteButton = styled(Button)`
  min-height: unset;
  & span {
    font-size: 12px;
    vertical-align: inherit;
  }

  @media only screen and (max-width: 576px) {
    & span {
      font-size: 7px;
      vertical-align: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`