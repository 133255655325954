import {API_BASE_URL} from 'api/config';
import {createAxios} from "./axios";

const loginApi = createAxios({
    baseURL: API_BASE_URL + '/patient-login'
});

export const login = mobileNumber => {
    return loginApi.post('/login', mobileNumber);
}

export const confirm = request => {
    return loginApi.post('/confirm', request);
}

export const logout = request => {
    return loginApi.post('/logout', request);
}