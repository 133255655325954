import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const StyledCol = styled(Col)`
    height: calc(100% - 17px);
    &.form > .container-fluid {
        height: inherit;
    }
`;

function AppointmentSection(props) {
    const { title, children, isMessageCard = false } = props;

    return (
        <>
            <Row style={{height: '100%'}}>
                {/* <Col xs={12} md={12} lg={12} style={{height: isMessageCard ? '98px' : '20px'}} className='mb-3'> */}
                <Col xs={12} md={12} lg={12} className='mb-3'>
                    <h4 className='text-center'>
                        <strong className='text-uppercase text-bolder' style={{ color: '#343434' }}>{title}</strong>
                    </h4>
                    {
                        isMessageCard && <p 
                            className={'Appointment-Card-Sub-Message'}
                        >
                            This messaging service is not to be used in an emergency or for medical consultation. If your matter is urgent, please present to your local hospital emergency department or call emergency TRIPLE ZERO (000).
                        </p>
                    }
                </Col>
                <StyledCol className={title === 'Forms' && 'form'} xs={12} md={12} lg={12}>
                    {children}
                </StyledCol>
            </Row><br/>
        </>
    );
}

export default AppointmentSection;