import React, {FunctionComponent} from "react";
import Helmet from "react-helmet";

interface CollectionNoticeProps {
    title: string;
}

const CollectionNotice: FunctionComponent<CollectionNoticeProps> = (props) => {
    return (
        <>
            <Helmet>
                <style type="text/css">{`
        html, body {
            height: 100%;
            background-color: white !important;
        }
    `}</style>
            </Helmet>
            <div
                className="container"
                style={{
                    height: "100vh",
                    backgroundColor: "#FFF",
                }}
            >
                <h1 className="display-3">
                    Use and Disclosure of Information
                </h1>
                Your personal and health information may be used by the NSW Public Health Service, or disclosed outside
                of the health service, to enable appropriate care and treatment to be provided to you. For example, your
                information may be used or disclosed to your GP, to another treating health service or hospital, to the
                Ambulance Service of NSW, to a specialist for a referral, or for pathology tests.


                Your personal and health information may be used and disclosed for purposes connected with the Florence
                online patient portal.


                Personal information may also be used or disclosed for the purposes relating to the funding, management,
                planning and evaluation of the health services and otherwise as authorised or required by law.


                Your personal information held in NSW Health’s database such as name, phone number and email address may
                be used to contact in relation to your appointment.
                For more information please refer to the <a href={'https://www.health.nsw.gov.au/patients/privacy/Documents/privacy-leaflet-for-patients.pdf'} target={'_blank'}>NSW Health Privacy Leaflet for Patients.</a>
            </div>
        </>
    );
};

export default CollectionNotice;
