import React from 'react';
import { Modal } from 'antd';
const MapModal = (props) => {
  const { title, visible, onClose, src } = props;

  return (
    <div id='mapdiv'>
      <Modal
        title={title}
        className='map-modal'
        visible={visible}
        footer={[]}
        onCancel={onClose}
        width={'100vw'}
        height={'100vh'}
        style={{ top: 0, padding: 0, margin: 0 }}
      >
        <iframe
          id='mapframe'
          scrolling='no'
          style={{
            position: 'absolute',
            top: '48px',
            left: 0,
            height: 'calc(100vh - 48px)',
            width: '100vw',
            overflow: 'hidden',
          }}
          title={title}
          src={src}
        ></iframe>
      </Modal>
    </div>
  );
};

export default MapModal;
