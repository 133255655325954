import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'styles/Nav.scss';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import MenuOpen from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import ExitToApp from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button, Modal } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import NotificationList from 'components/Notifications/NotificationList';
import UserDetailsModal from 'components/Modals/UserDetailsModal';
import UserDetailsChangedModal from 'components/Modals/UserDetailsChangedModal';
import HelpIcon from '@material-ui/icons/Help';
import { toggleSideBarVisibility } from 'redux/actions/ui';
import { keycloak } from '../../../api/keycloak';
import { getUser } from 'api/users';
import { hideSettings } from 'api/settings';
import { SET_CURRENT_USER } from 'redux/actions/types/currentUser';
import Cookies from 'js-cookie';
import { TOKEN_NAME, TOKEN_KEY } from 'constants/index';
import SettingsModule from 'modules/SettingsModule';
import Interweave from 'interweave';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useAppSelector } from "../../../hooks/use-app-selector";
import { logout } from 'api/login';
import { getPublicSetting } from 'api/public/settings';


function SlickTopMenu(props) {
  const dispatch = useDispatch();

  const { fullName, isSidebarVisible, toggleSideBarVisibility } = props;
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { confirm } = Modal;
  const [currentUser, setCurrentUser] = useState(null);
  const tenantState = useSelector((state) => state.tenant);
  const sysPropertiesState = useSelector((state) => state.sysProperties);
  const [hideSettingsInMenu, setHideSettingsInMenu] = useState(false);
  const permissions = useAppSelector((state) => state.permissions.permissions);
  const [viewUserDetails, setViewUserDetails] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [isFaqModalVisible, setFaqModalVisibility] = useState(false);
  const [helper, setHelper] = useState();

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      fetchCurrentUser();
      // fetchHideSettings();

      const response = await getPublicSetting({
        category: 'TENANT',
        dataType: 'HTML',
        name: 'PATIENT_TENANT_FAQS_SCREEN',
        defaultValue: ''
      })

      if (response?.data?.value) {
        setHelper(response.data.value);
        // dispatch(setHelpScreenData(response.data.value));
      }
    }
    fetchData();

  }, []);

  const showLogoutModal = (e) => {
    confirm({
      title: 'Confirm',
      content: 'Are you sure you want to logout?',
      okText: 'Yes',
      cancelText: 'No',
      maskClosable: true,
      onOk: () => {
        message.loading(t('message.signing_out'), 0);
        // Handle logouts
        if (keycloak.hasRealmRole()) {
          keycloak.logout();
        } else if (Cookies.get(TOKEN_KEY)) {
          let tokenKey = Cookies.get(TOKEN_KEY);
          logout({ tokenKey: tokenKey })
            .then((data) => {
              console.log('logout', data);
              Cookies.remove(TOKEN_NAME);
              Cookies.remove(TOKEN_KEY);
              window.location.reload();
            }).catch(err => err);

          //   history.push('/');
        }
      },
    });
  };

  const fetchCurrentUser = async () => {
    const res = await getUser();
    dispatch({
      type: SET_CURRENT_USER,
      payload: res.data,
    });
    setCurrentUser(res.data);
  };
  const fetchHideSettings = async () => {
    const res = await hideSettings();
    const val = res && res.data ? res.data.value === 'true' : false;
    setHideSettingsInMenu(val);
  };
  return (<>
    <Modal
      title='Help'
      visible={isFaqModalVisible}
      onCancel={() => setFaqModalVisibility(false)}
      destroyOnClose={true}
      footer={null}
      centered
      bodyStyle={{ height: '90vh', overflowY: 'auto' }}
    >
      <Interweave content={sysPropertiesState?.helpScreenData ? sysPropertiesState?.helpScreenData  : helper} />
    </Modal>
    <div>
      <UserDetailsModal
        visible={viewUserDetails}
        setVisibility={setViewUserDetails}
        taskModal={setTaskModalVisible}
      />
      <UserDetailsChangedModal
        visible={taskModalVisible}
        setVisibility={setTaskModalVisible}
      />
      <Navbar className='fivef-navbar' expand='lg' bg='light' variant='light'>
        <Nav.Link className='fivef-user-dropdown d-lg-none'>
          <PersonIcon
            fontSize='large'
            onClick={() => {
              setViewUserDetails(true);
            }}
          />
        </Nav.Link>
        <Navbar.Brand
          className='fivef-navbar-brand text-center'
          href='#home'
          style={{
            textTransform: 'uppercase',
            lineHeight: '15px',
          }}
        >
          {tenantState.branding.page.navbarHeaderTitle}
          <br />
          <small
            style={{
              color: `${tenantState.branding.theme.palette.primary.light}`,
              fontSize: '11px',
              fontWeight: '700',
              letterSpacing: '1px',
            }}
          >
            {tenantState?.branding?.page?.appSubTitle ||
              'Digital Patient Journey'}
          </small>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        <Button
          className='btn-toggle-mb-sidebar d-none d-sm-none'
          onClick={() => toggleSideBarVisibility()}
        >
          {isSidebarVisible ? (
            <MenuOpen fontSize='large' />
          ) : (
            <Menu fontSize='large' />
          )}
        </Button>
       
        <Nav.Link className='btn-toggle-mb-sidebar d-flex d-lg-none'>
          <HelpIcon
              fontSize='large'
              onClick={() => setFaqModalVisibility(true)}
          />
          <NotificationList />
        </Nav.Link>

        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link onClick={() => toggleSideBarVisibility()}>
              {isSidebarVisible ? (
                <MenuOpen fontSize='large' />
              ) : (
                <Menu fontSize='large' />
              )}
            </Nav.Link>
          </Nav>
          <Nav className='ml-auto'>
            <Nav.Link className='d-none d-lg-flex'>
              <NotificationList />
            </Nav.Link>
            <Nav.Link className='fivef-user-dropdown'>
              <PersonIcon
                fontSize='large'
                onClick={() => {
                  setViewUserDetails(true);
                }}
              />
            </Nav.Link>
            <NavDropdown
              className='fivef-user-dropdown'
              title={
                <span style={{ minWidth: '160px', display: 'inline-block' }}>
                  Welcome{' '}
                  {currentUser
                    ? `${currentUser?.firstName} ${currentUser?.lastName}`
                    : `Patient!`}
                </span>
              }
              id='collasible-nav-dropdown'
              style={{ width: '100%!important' }}
            >
              {permissions.Settings?.length > 0 ? (
                <NavDropdown.Item onClick={() => setIsSettingsOpen(true)}>
                  <SettingsIcon /> Account Settings
                </NavDropdown.Item>
              ) : null}
              <NavDropdown.Item onClick={showLogoutModal}>
                <ExitToApp /> Logout
              </NavDropdown.Item>
            </NavDropdown>
            <HelpIcon
              fontSize='large'
              className='hover:cursor-pointer mt-2 ml-2'
              onClick={() => setFaqModalVisibility(true)}
            />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <SettingsModule
        isOpen={isSettingsOpen}
        handleClose={() => setIsSettingsOpen(false)}
      />
    </div>
  </>
  );
}

export default connect(
  (state) => {
    return {
      isSidebarVisible: state.ui.isSidebarVisible,
    };
  },
  (dispatch) => {
    return {
      toggleSideBarVisibility: () => dispatch(toggleSideBarVisibility()),
    };
  }
)(SlickTopMenu);
